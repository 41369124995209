.mycart {
  width: 100%;
  margin-bottom: 10px;
  align-self: flex-start;
  font-weight: 400;
  font-size: 14px;
  display: block;

  &-header {
    height: 50px;
    padding: 0 18px;
    align-items: center;
    display: flex;    
    svg {
      margin-right: 10px;
    }
  }
  &-body {
    max-height: calc(100vh - 500px);
    overflow-y: auto;
    padding: 5px 10px;
    &_item {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      &.discount {
        height: 0px;
        margin-bottom: 20px;
      }
      .cartName {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.demo {
          color: #ffc600;
        }
      }
      .close {
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s, opacity 0.5s linear;
        position: absolute;
        right: 0px;
      }
      .prices {
        opacity: 1;
        visibility: visible;
        display: inline;
        transition: visibility 0s, opacity 0.5s linear;
      }
    }
    &_item:hover {
      .prices {
        opacity: 0.8;
        // visibility: hidden;
        color: gray;
      }
      .close {
        opacity: 1;
        visibility: visible;
      }
    }
    &_empty {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      white-space: nowrap;
      height: fit-content;
      font-size: 12px;
      .zero {
        margin: 15px 0;
        font-size: 30px;
      }
    }
  }
  .myCartBtnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mycart-btn {
    border: none;
    outline: none;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgb(20, 137, 240);
    color: white;
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: 'Montserrat', sans-serif !important;
    cursor: pointer;
  }
  .mycart-btn:hover {
    background: #58a0e5;
  }
  .mycart-btn:disabled {
    cursor: auto;
    opacity: 0.6;
    &:hover {
      background: #58a0e5;
    }
  }
  .subTotal {
    border-top: 1px solid gray;
  }
  .subTotalExplain {
    text-align: center;
    font-size: 12px;    
  }
  .w600 {
    font-weight: 600;
  }
}
.fund-btn {
  font-weight: 600;
  font-size: 16px;
  @media only screen and (max-width: 540px) {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1366px) {
  .mycart {
    max-width: 220px;
    font-size: 14px;
    .mycart-btn {
      font-size: 14px !important;
    }
  }
}
.test {
  color: #6cb5fa #ac09dd;
  background: linear-gradient(#d8670a, #fa811e, #d8670a);
}
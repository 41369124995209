.depositForm {
  background: #00000090;
  border-top: 2px solid #025498;
  padding: 14px;
  /* width: calc(100% / 3); */
  position: relative;
}
.depositForm_l {
  background: #ffffff90;
  border-top: 2px solid gray;
  padding: 14px;
  /* width: calc(100% / 3); */
  position: relative;
}

form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.formLabel {
  width: 100%;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
}
.payInp {
  width: calc(100% - 120px);
  position: relative;
  margin-right: 10px;
}
.payInp .input label {
  margin-bottom: 0px;
}
.btnCont {
  display: flex;
}
.payBtn {
  border: none;
  outline: none;
  height: 40px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 68px;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-style: italic;
  margin: 0 2px;
  /* padding: 10px 8px; */
  border-radius: 5px;
  cursor: pointer;
}
.payBtn .mhcTtext {
  margin-right: 2px;
}
.payBtn img {
  width: 100%;
  height: 100%;
}

.payBtn:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.payBtn:disabled {
  cursor: default;
  transform: scale(1);
}
.paypal {
  position: absolute;
  width: 50px;
  top: 0;
  left: 65px;
}

.payMain {
  width: 100%;
  max-height: 100vh;
}
.maintenanceNotification {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
.notificationText {
  font-size: 32px;
}

.test {
    color: #838383;
}
.topBarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-size: 12px;

    .line-top {
        display: flex;
        align-items: center;
        width: 100%;
        background: #000000;
        color: #ffffffe0;
        height: 24px;
        overflow-x: hidden;
    }
    .line-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;        
        background: linear-gradient(#202020, #3c3c3c, #464545, #505050, #505050, #3a3a3a, #2a2a2a, #181818);
        // background: linear-gradient(#acabab, #dad8d8, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #dad8d8, #acabab);
        color: gray;
        padding: 1px 0;
        z-index: 1;
    }
    .line-middle {
        display: flex;
        align-items: center;
        height: 8px;
        justify-content: space-between;
        width: 100%;
        background: linear-gradient(#4a4a4a, #1e1e1e, #000000, #383838, #606060);
        // background: linear-gradient(#f0efef, #ffffff, #ffffff, #dad8d8, #acabab);
        overflow: hidden;

        .middle-left {
            width: 140px;
            display: flex;
            align-items: center;
        }
        .middle-center {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 30px;
        }
        .middle-right {  
            width: 140px;
            display: flex;
            align-items: center;
        }
        .slider {
            -webkit-appearance: none;
            width: 100%;
            height: 4px;
            background: #000;
            outline: none;
            opacity: 0.7;
            -webkit-transition: .2s;
            transition: opacity .2s;
        }        
        .slider:hover {
            opacity: 1;
        }        
        .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 14px;
            height: 8px;
            background: #000;
            cursor: pointer;
            border: 1px solid gray;
        }        
        .slider::-moz-range-thumb {
            width: 14px;
            height: 8px;
            background: #000;
            cursor: pointer;
            border: 1px solid gray;
        }
    }    
    .item-sold {
        display: flex;
        padding-top: 2px;
    }
    
    .sold-domain {
        color: #02fa02;
        font-weight: 600;
    }
    .resold-domain {
        color: #ffae00;
        font-weight: 600;
    }
    .expired-domain {
        color: #ff0000;
        font-weight: 600;
    }
    .gift-domain {
        color: #ff00ff;
        font-weight: 600;
    }
    .bottom-domain {
        color: #0077ff;
        font-weight: 600;
    }
    .v-line {
        margin: 0 5px;
    }
    .h-line {
        margin: 0 10px;
        @media only screen and (max-width: 768px) {
            margin: 0 5px;
        }
    }
    .marginR {
        margin-right: 5px;
    }
    .marginL {
        margin-left: 5px;
        margin-top: -1px;
    }
    .marginRR {
        margin: 2px 5px 0 0;
    }
    .marginLL {
        margin-left: 5px;
    }
    .text-wrap {
        white-space: nowrap;
    }
    .item-left {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        min-width: 50px;
    }
    .item-center {    
        display: flex;
        align-items: center;
        overflow-x: auto;
        width: calc(100vw - 260px);
        @media only screen and (max-width: 768px) {
            width: calc(100vw - 180px);
        }
        @media only screen and (max-width: 450px) {
            width: calc(100vw - 10px);
        }
        .bottom-item-div {
            display: flex;
            align-items: center;
        }   
        .item-log {
            display: flex;
            flex-direction: row;
            // width: calc(100vw - 360px);
        }
    }
    .item-plugin {
        color: #fff; //#ffae00;
        // text-shadow: 2px 2px #0077ff;
        white-space: nowrap;
        margin: 0 10px;
        font-size: 11px;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-radius: 15px;
        cursor: pointer;
        @media only screen and (max-width: 1024px) {
            display: none;
        }
    }
    .item-right {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        min-width: 50px;
    }
    .headerIcon {
        cursor: pointer;
        // :hover {
        //     transform: scale(1.1);
        // }
    }
    .guide-img {
        display: flex;
        flex-flow: column nowrap;
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .expand-icon {
        width: fit-content;
        margin-left: 20px;
    }
    .topIconCart {
        position: relative;
        cursor: pointer;
    }
    .topCart {
        background: rgb(250, 60, 59);
        border-radius: 50%;
        position: absolute;
        font-size: 10px;
        padding: 2px;
        height: 14px;
        line-height: 12px;
        min-width: 14px;
        text-align: center;
        display: inline-block;
        color: #fff;
        top: -3px;
        right: -8px;
    }
    .headerTopAccountDropdown {
        position: absolute;
        transform: translateY(-5px);
        border-radius: 1px;
        box-shadow: 0 18px 50px rgba(0, 0, 0, 0.16);
        top: 75px;
        left: calc(100vw - 280px);
        z-index: 15;
        -webkit-transition: all 0.2s ease 0s;
        transition: all 0.2s ease 0s;
        width: 260px;
    }
    .headerTopCartDropdown {
        position: absolute;
        transform: translateY(-5px);
        border-radius: 1px;
        box-shadow: 0 18px 50px rgba(0, 0, 0, 0.16);
        top: 75px;
        left: calc(100vw - 380px);
        z-index: 15;
        -webkit-transition: all 0.2s ease 0s;
        transition: all 0.2s ease 0s;
        width: 260px;
    }  
}
.item-center::-webkit-scrollbar {
    height: 0px;
}  
.icon-expand {    
  @media screen and (max-width: 450px) {
    display: none;
  }
}
.icon-sound {
    
}
.icon-search {       
  @media screen and (max-width: 450px) {
    display: none;
  }
}
.icon-cart {       
  @media screen and (max-width: 450px) {
    display: none;
  }
}
.icon-dark {
    
}
.icon-menu {   
    @media screen and (max-width: 450px) {
      display: none;
    }    
}
.tr-c {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    padding: 2px 10px 2px 15px;
    align-items: center;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
        color: #d61df7;
      }
}
// .tr-c-keyword {
//     font-size: 14px;
//     display: flex;
//     flex-direction: row;
//     padding: 10px;
//     &:hover {
//         cursor: pointer;
//         opacity: 0.8;
//         color: #d61df7;
//       }
// }
.domain-c {
    font-size: 14px;
    padding: 10px 0;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
        color: #fab004;
      }
}
.td-domain { 
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    width: 40%;
}
.td-domain-keyword {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    width: 200%;
}
.td-url { 
    text-align: left;
    opacity: 0.8;    
    width: 40%;
}
.td-btn { 
    text-align: center;
    font-weight: 800;
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 20%;
}
.td-star { 
    text-align: center;
    font-weight: 800;
    display: flex;
    align-items: center;
    :hover {
        transform: scale(1.1);
    }
}
.td-btn-keyword {
    text-align: center;
    font-weight: 800;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
}
.btn-dropdown-keyword {
    padding: 3px 0px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    font-size: 12px;
    width: 140px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.btn-dropdown {
    padding: 3px 0px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    font-size: 12px;
    width: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.btn-buy {
    background: linear-gradient(#60fb12, #41ae0a);
    padding: 3px 0px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    font-size: 12px;
    margin: 0 2px;
    width: 60px;
}
.buy-keyword {
    padding: 3px 0px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    font-size: 12px;
    width: 130px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.search-key-input {
    border-radius: 50px 0 0 50px;
    border: none;
    outline: none;
    padding: 0 5px 0 35px;
    font-size: 14px;
    height: 38px;
    width: 100%;
}
.tagPrefix {
    color: black;
    position: absolute;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin: 6px 0px 0px 8px;
    float: left;
    left: 0px;
}
.tagPrefix_home {
    color: black;
    position: absolute;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin: 10px 0px 5px 10px;
    float: left;
    left: 0px;
}
.freedom-input {
    display: flex;
    flex-direction: row;
    align-items: center;    
}
.freedom-img {
    width: 70%;
    margin-right: 10px;
    @media only screen and (max-width: 1366px) {
        width: 50%;
        height: 30px;
    }
    @media only screen and (max-width: 1024px) {
        width: 50%;
        height: 30px;
    }
    @media only screen and (max-width: 768px) {
        width: 50%;
        height: 30px;
    }    
    @media only screen and (max-width: 540px) {
        display: none;
    }
}


@media (max-width: 640px) { 
}
@media (max-width: 768px) { 
    .freedom-img {
        width: 50%;
        height: 30px;
    }
}
@media (max-width: 1024px) {
}
@media (max-width: 1280px) { 
}
@media (max-width: 1536px) { 
}
.form_edit {
  padding: 40px 35px 30px;
  background: #00000090;
  border-top: 2px solid #025498;
  color: white;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
}
.form_edit_l {
  padding: 40px 35px 30px;
  background: #ffffff90;
  border-top: 2px solid transparent;
  color: #000;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
}
.formCol1,
.formCol2 {
  width: calc(50% - 12px);
}
/* .formCol2 {
  flex-basis: calc(50% - 25px);
  padding-left: 25px;
} */
.checkboxAftertit {
  font-size: 12px;
}
.formTit {
  font-size: 18px;
  margin-bottom: 30px;
}
.checkGroup {
  margin-bottom: 30px;
}
.domainDescribe {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.domainDescribe label {
  width: 33%;
}
.threecol {
  display: flex;
  width: calc(100% / 3);
}
.twocol {
  display: flex;
  width: calc(100% / 2);
  margin-top: 15px;
}
.onecol {
  display: flex;
  width: 100%;
}
.formRow {
  display: flex;
  margin-bottom: 15px;
  gap: 4px;
}
.social {
  display: flex;
  width: 100%;
  margin-top: 15px;
}
.descriptionContainer {
  display: flex;
  width: 100%;
  margin-top: 15px;
  flex-direction: column;
}
.descriptionContainer label {
  font-size: 12px;
}
.descriptionTextArea {
  resize: none;
  width: 100%;
  height: 120px;
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif !important;
  margin-top: 4px;
  box-sizing: border-box;
}
.imageContainer {
  position: relative;
  height: 150px;
  margin-top: 30px;
}
.imageContainer label {
  font-size: 12px;
}
.imageContainer input {
  width: 100%;
  height: 120px;
  opacity: 0;
  z-index: 3;
  position: absolute;
  top: 30px;
  left: 0;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.imageContainer img {
  position: absolute;
  top: 30px;
  left: 0;
  height: 120px;
  z-index: 2;
}
.imageContainer .defaultImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: linear-gradient(#ffc600, #f48f73);
  color: #000; */
  border-radius: 10px;
  padding: 16px 24px;
  font-size: 16px !important;
  font-weight: 700 !important;
}
.border {
  border-bottom: 1px solid #354955;
  margin: 30px 0;
  width: calc(100% - 10px);
}
.modtit {
  font-size: 14px;
  font-weight: 600;
}
.formBtn {
  border: none;
  outline: none;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  border-radius: 10px;
  font-size: 16px !important;
  font-weight: 700 !important;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 5px;
}

.formBtn:hover {
  background: linear-gradient(#ffc600, #f48f73);
  cursor: pointer;
}
.formBtn:active {
  background: linear-gradient(#f48f33, #fbb212);
}
.formBtn:disabled {
  background: #a2a2a2;
  cursor: default;
}
@media only screen and (max-width: 1200px) {
  .formCol1 {
    flex-basis: calc(20% - 15px);
    padding-right: 15px;
  }
  .formCol2 {
    flex-basis: 50%;
  }
  .formCol3 {
    flex-basis: calc(30% - 15px);
    padding-left: 15px;
  }
  .formTit {
    font-size: 14px;
  }
  .modtit {
    font-size: 12px;
  }
}

.VPNContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  .ipStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border: 1px solid;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 700;
  }
  .ipSite {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .mTop {
    margin: 10px 0;
  }

  .vpnbtn {    
    cursor: pointer;
    // margin: 5px 5px 0;
  }
  .vpn-dropdown {
    position: relative;
  }
  /* Dropdown Content (Hidden by Default) */
  .vpn-content {
    display: none;
    position: absolute;
    font-size: 13px;
    padding: 10px 15px;
    border-radius: 5px;
    min-width: 160px;
    width: fit-content;
    top: 45px;
    right: 15px;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }  
  
  /* Links inside the dropdown */
  .vpn-content a {
    color: black;
    width: 45px;
    height: 45px;
    text-decoration: none;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  
  /* Change color of dropdown links on hover */
  .vpn-sub a:hover {background-color: #98d6f5}
  .vpn-sub-d a:hover {background-color: #b056c7}
  
  /* Show the dropdown menu on hover */
  .vpn-dropdown:hover .vpn-content {
    display: block;
  }
}




  

  @keyframes shine {
    from { -webkit-mask-position: 150%; }
    to { -webkit-mask-position: -50%; }
  }


  

// .modal {
//   z-index: 123;
//   position: fixed;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   &-content1 {
//     width: 620px;
//     padding: 25px 30px;
//     background: rgba(0, 0, 0, 0.7);
//     &__header {
//       display: flex;
//       justify-content: space-between;
//       p {
//         font-size: 24px;
//         font-weight: bold;
//       }
//       .modal-close {
//         margin-right: -15px;
//         cursor: pointer;
//       }
//     }
//   }
// }
.modal-inp {
  margin: 20px 0px !important;
  & input {
    height: 40px !important;
    border-radius: 5px !important;
  }
}

// .modal-content__btns {
//   display: flex;
//   align-items: flex-end;
//   justify-content: flex-end;
//   height: 35px;
// }

// .modal-content__btns .btn {
//   height: 35px;
//   padding-left: 20px;
//   padding-right: 20px;
//   text-decoration: none;
//   font-size: 14px;
//   cursor: pointer;
//   justify-content: center;
//   width: 60px;
//   align-items: center;
// }
.modal-content__btns .btn:first-child {
  color: white;
  border: none;
  border-radius: 20px;
  background: gray;
}
.modal-content__btns .btn:last-child {
  margin-left: 25px;
  color: white;
  border-radius: 20px;
  background: linear-gradient(#873f99, #311438);
}
// .modal-content__btns .btn:last-child:active {
//   background: linear-gradient(#f48f33, #fbb212);
// }

// .modal.animated-enter {
//   opacity: 0.01;
// }
// .modal.animated-enter-active {
//   opacity: 1;
// }

// .modal.animated-exit {
//   opacity: 0;
// }
// .modal.animated-exit-active {
//   opacity: 0;
// }

.input {
  width: 100%;
  padding-right: 10px;
  label {
    font-size: 12px;
    margin-bottom: 4px;
    display: flex;
  }

  .selectContainer {
    display: flex;

    .selectWrapper {
      width: 35%;
      position: relative;
      .select {
        border-left: 1px solid white;
        border-bottom: 1px solid white;
        border-top: 1px solid white;
        width: 100%;
        display: flex;
        height: 40px;
        padding: 10px 10px;
        cursor: pointer;
        border-radius: 0;
        appearance: none;
        outline: 0;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        font-size: 14px;
      }
      .selectArrow {
        position: absolute;
        top: 18px;
        right: 10px;
        width: 0;
        height: 0;
        pointer-events: none;
        border-width: 6px 4px 0 4px;
        border-style: solid;
        border-color: white transparent transparent transparent;
      }
    }
    input {
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      border: 1px solid white;
      height: 40px;
      font-size: 14px;
      padding: 0 10px;
      color: white;
      width: 100%;
      outline: none;
    }
  }
}

.error {
  .selectContainer {
    input {
      border: 1px solid red;
    }
  }
}

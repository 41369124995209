.table {
  height: calc(100% - 136px) !important;
  background: #00000090;
  border-top: 2px solid #025498;
  padding: 25px;
}
.table_l {
  height: calc(100% - 136px) !important;  
  background: #ffffff90;
  border-top: 2px solid gray;
  padding: 25px;
  color: #000;
}

.header {
  display: flex;
  width: 100%;
  height: 30px;
}
.cell {
  width: calc(100% / 5);
  font-size: 14px;
}
.dateCell,
.userCell {
  min-width: 150px;
}
.statusCell {
  display: flex;
  align-items: center;
  gap: 5px;
}
.withdrawConfirm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.withdrawConfirmBtn,
.withdrawConfirmDisableBtn {
  padding: 0 10px;
  font-size: 18px;
  color: #2ec814;
  transition: all 0.2s ease-in-out;
}
.withdrawConfirmDisableBtn {
  color: #606061;
  pointer-events: none;
}
.withdrawRejectBtn {
  padding: 0 10px;
  font-size: 18px;
  color: red;
  transition: all 0.2s ease-in-out;
}
.withdrawConfirmBtn:hover,
.withdrawRejectBtn:hover {
  color: #fbb212;
  transform: scale(1.5);
}
.header .cell {
  font-weight: 600;
}
.item,
.item_l,
.itemWithdraw {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #35495e;
}
.item:hover,
.itemWithdraw:hover {
  background-color: rgba(27, 27, 27, 0.6);
  cursor: pointer;
}
.item_l:hover {
  background-color: rgba(228, 227, 227, 0.6);
  cursor: pointer;
}
.item .cell,
.item_l .cell,
.itemWithdraw .cell {
  height: 75px;
  align-items: center;
  display: flex;
  word-break: break-all;
  padding: 20px 0;
}

.directPaymentType {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  min-width: 100px;
  background-color: #0093fc;
  border-radius: 4px;
  font-weight: 800;
  font-style: italic;
  font-size: 12px;
  text-decoration: none;
  color: #fff;
}
.directPaymentType:hover {
  text-decoration: none;
}

.ethType {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  min-width: 100px;
  background-color: #be6f14;
  border-radius: 4px;
  font-weight: 800;
  font-style: italic;
  font-size: 12px;
  text-decoration: none;
  color: #fff;
}
.ethType:hover {
  text-decoration: none;
}

.bnbType {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  min-width: 100px;
  background-color: #208341;
  border-radius: 4px;
  font-weight: 800;
  font-style: italic;
  font-size: 12px;
  text-decoration: none;
  color: #fff;
}
.bnbType:hover {
  text-decoration: none;
}

.commonType {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  min-width: 100px;
  background-color: transparent;
  border: 1px solid #be6f14;
  border-radius: 4px;
  font-weight: 800;
  font-style: italic;
  font-size: 12px;
  text-decoration: none;
}

.giftType {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  min-width: 100px;
  border: 1px solid #1d221b;
  background: #353f32;
  border-radius: 4px;
  font-weight: 800;
  font-size: 12px;
  text-decoration: none;
  color: #fff;
}

.optionText {
  margin: 0 1px;
}
.directPaymentDirection {
  margin-left: 5px;
  color: #fff;
  font-size: 16px;
}

.itemWithdraw {
  background-color: #35495e46;
}

.infiniteScroll {
  height: calc(100% - 25px);
}
@media (max-width: 640px) { 
}
@media (max-width: 768px) { 
}
@media (max-width: 1024px) {
  .table, .table_l {
    padding: 10px;
    height: 600px !important;
  }  
  .cell {
    font-size: 12px;
  }
  .directPaymentType, 
  .ethType, 
  .bnbType, 
  .commonType, 
  .giftType {
    min-width: 60px;
    white-space: nowrap;
  }
  .statusCell {
    flex-direction: column-reverse;
  }
}
@media (max-width: 1280px) { 
}
@media (max-width: 1536px) { 
}

@media (max-width: 420px) { 
  .cell {
    font-size: 11px;
  }
}

.performanceContent {
  border-radius: 5px;
  padding: 24px;
  width: 49%;
  box-sizing: border-box;
  border: 1px solid gray;
  box-shadow: rgba(88, 102, 126, 0.1) 0px 4px 24px, rgba(88, 102, 126, .12) 0px 1px 2px;
  font-weight: 600;
  line-height: 24px;
  min-height: 180px;
  @media only screen and (max-width: 1024px) {
    padding: 10px;
  }

  .assetName {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 24px;
    @media only screen and (max-width: 1024px) {
      font-size: 12px;
    }
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 8px;
      @media only screen and (max-width: 1024px) {
        width: 20px;
        height: 20px;
      }
    }
  }
  .title {
    font-size: 12px;
  }
  .profitAmount {
    color: #919599;
  }
}
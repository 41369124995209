
.searchDiv {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 18px;
    margin: 0 5px;
    border: 1px solid #a1a1a1;
    border-radius: 10px;
    @media only screen and (max-width: 768px) {
        display: none;
    }
}
.searchBtn {
    width: 100px;
    border-radius: 0 10px 10px 0;
    border: none;
    outline: none;
    font-size: 12px;
    padding: 5px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.explorer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
}
.exTxt {
    margin-right: 5px;
}
.searchInput {
    border: none;
    outline: none !important;
    border-radius: 10px 0 0 10px;
    padding-left: 18px;
    font-size: 13px;
}
.searchInput:focus {
    outline: none !important;
}
.symbolsharp {
    position: absolute;
    left: 5px;
}
.socialContainer {
    position: absolute;
    right: 102px;
}
.socialIcon {
    margin: 0 2px;
    cursor: pointer;
}
.hintItemContent {
    position: absolute;
    right: 102px;
    display: flex;
    flex-direction: row;
}
.hintItem {
    cursor: pointer;
    margin: 1px 1px 0;
    border-radius: 3px;
    padding: 0px 3px;
    background: #ffffff;
    border: 1px solid #c3c0c0;
    color: black;
    height: 14px;
    display: flex;
    align-items: center;
}
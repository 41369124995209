.wrapper {
  width: 100%;
}
.renewBtn {
  color: #fff;
  background-color: green;
  border: 1px solid #fff;
  box-shadow: 0 0 10px 2px #fe9800, 0 2px 10px 0 #059939;
  border-radius: 5px;
  padding: 3px;
  font-size: 12px;  
  cursor: pointer;
  height: 25px;
}
.keywordSpan {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-top: 3px;
  cursor: pointer;
}
.keyWords {
  color: #dddddd;
  text-decoration: underline;  
  margin-right: 5px;
}
.addurlSpan {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-top: 8px;
  cursor: pointer;
}
.domainNameItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  margin-left: 5px;
}
.domain_name {
  display: flex;
  align-items: center;
}
.all {  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.suball {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
}
.domain_icon {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 420px) {
    flex-direction: row;
  }
}
.table {
  width: 100%;
  th {
    padding: 20px 15px;
    border-bottom: 1px solid #1f2024;
    font-size: 18px;
    border-left: none;
    border-right: none;
    border-collapse: collapse;
    text-align: left;
    position: relative;
    @media screen and (max-width: 420px) {
      font-size: 13px;
    }
  }

  td {
    border-bottom: 1px solid #20303f;
    padding: 10px;
    text-align: left;
    @media screen and (max-width: 420px) {
      font-size: 13px;
    }
  }
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.subrow {
  display: flex;
}
.topsubrow {
  display: flex;
}

.targetBtn,
.resellBtn,
.stopResaleBtn,
.videoBtn,
.giftBtn {
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  color: white;
  background: black;;
  width: 120px;
  margin: 3px 5px;
  border-radius: 10px;
}
.targetBtn {
  color: #00adfe;
  box-shadow: 0 0 13px 5px #00adfe, 0 6px 20px 0 #0072fe;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid #fff;
  width: 120px;
  height: 35px;
  font-weight: 700;
  padding: 0;
  margin-right: 5px;
}            
.videoBtn {
  color: #ffffff;
  box-shadow: 0 0 13px 5px #865cd4, 0 6px 20px 0 #2a0273;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid #fff;
  width: 120px;
  height: 35px;
  font-weight: 700;
  padding: 0;
}
.resellBtn,
.stopResaleBtn {
  color: #f69829;
  box-shadow: 0 0 13px 5px #8fc21c, 0 6px 20px 0 #4b6a04;;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid #fff;
  width: 120px;
  height: 35px;
  font-weight: 700;
  padding: 0;
}
.giftBtn {
  color: #ffffff;
  box-shadow: 0 0 13px 5px #ec59cf, 0 6px 20px 0 #621252;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid #fff;
  width: 120px;
  height: 35px;
  font-weight: 700;
  padding: 0;
}
.domaindashboardsearchinp {
  height: 40px;
  font-size: 14px;
  padding: 0 10px;
  margin-bottom: 4px;
  border: 1px solid gray;
  display: flex;
  width: 100%;
  outline: navajowhite;
  box-sizing: border-box;
}
.expiredListContainer {
  width: 320px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #025498;
  position: absolute;
  z-index: 1;
  padding: 6px 12px;
  max-height: 240px;
  overflow-y: auto;
  box-sizing: border-box;

  .expiredListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin: 8px 0;
    cursor: pointer;
    &:hover {
      color: #fbb212;
    }
    .itemId {
      word-wrap: break-word;
      max-width: 50%;
    }
  }
}
.domainUrls {
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 1280px) {
    width: 150px;
  }
  @media screen and (max-width: 450px) {
    width: 120px;
  }
}
.smfont {
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  white-space: nowrap;
}
.smThree {
  width: 30%;
  // white-space: nowrap;
}
.smFour {
  width: 10%;
}
.date {
  font-weight: 600;
}
.default {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-left: 150px;
  cursor: pointer;
}
.defaulttxt {
  margin: 0 5px;
}














.form {
  background: #00000090;
  border-top: 2px solid #025498;
  flex-direction: row;
  flex-wrap: wrap;
  color: white;
  margin: 10px 0 15px;
  position: relative;
}
.form_l {
  background: #ffffff90;
  border-top: 2px solid transparent;
  flex-direction: row;
  flex-wrap: wrap;
  color: #000;
  margin: 10px 0 15px;
  position: relative;
}

.btnCopy {
  background: linear-gradient(#873f99, #311438);
  border: none;
  padding: 5px;
  cursor: pointer;
}

.threecol {
  width: calc(100% / 3);
  display: inline-flex;
  padding: 0px 20px;
  margin: 5px 0;
  position: relative;
  @media screen and (max-width: 420px) {
    padding: 0px 5px;
  }
}
.fourcol {
  width: calc(100% / 4);
  display: inline-flex;
  padding: 5px 20px;
  margin: 5px 0;
  @media screen and (max-width: 420px) {
    padding: 0px 5px;
    width: 70%;
  }
  @media screen and (max-width: 1536px) {
    width: 50%;
  }
}
.twocol {
  display: flex;
  flex-direction: column;
  width: calc(100% / 2);
  padding: 0px 20px;
  margin: 5px 0;
  @media screen and (max-width: 420px) {
    padding: 0px 5px;
  }
}
.onecol {
  display: flex;
  width: 100%;
}
.formRowBetween { //
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.formRowBetween_crypto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.formRowBetween_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.formRowBetween_gps {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.formRowEnd { // 2
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 20px;
  padding: 15px 20px;
  @media screen and (max-width: 420px) {
    margin: 0 5px;  
    padding: 10px 5px;
  }
}
.formRowEnd_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 20px;
}
.formRowStart { //3
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.rowUrl { 
  display: flex;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  margin-top: 12px;
}
.rowUrl1 { 
  display: flex;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  margin-top: -5px;
}
.allUrl {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 20px;  
  @media screen and (max-width: 420px) {
    margin: 0 5px;  
  }
}
.targeturl {
  position: relative;
  margin: 0 2px;
  width: 33%;
}
.targeturl1 {
  width: 100%;
  position: relative;
  max-width: 35%;
  width: 32%;
}
.keyword_input {
  background: transparent;
  border: none;
  margin-right: 5px;
  height: 20px;
  width: 150px;
  border-bottom: 1px solid gray;
  color: white;
}
.keyword_input:focus {
  outline: none !important;
}
.card {
  padding: 5px 10px;
  border: 1px solid gray;
  border-radius: 8px;
}
.gps_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.gps_input {
  background: transparent;
  border: none;
  margin-right: 5px;
  height: 20px;
  width: 40%;
}
.gps_input:focus {
  outline: none !important;
}
.circleborder {
  border: 1px solid;
  border-radius: 50%;
  padding: 2px;
}
.txtarea {
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid grey;
  resize: none;
}
label {
  font-size: 12px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 10px;
}
.topkeyword {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.wtop {
  margin-right: 5px;
}
.copyicon {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;  
  @media screen and (max-width: 420px) {
    right: 10px;
  }
}
.closeicon {
  position: absolute;
  right: 5px;
  top: 30px;
  cursor: pointer;
}
.extraicon {
  position: absolute;
  left: 5px;
  top: 30px;
  width: 20px;
  cursor: pointer;
}
.edittxt_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  text-decoration: underline;
  cursor: pointer;
}
.edittxt {
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
}
.saveBtn {
  border: none;
  text-align: center;
  width: 100px;
  height: 30px;
  margin: 0 20px 20px 5px;
  padding: 5px 0px;
  border-radius: 5px;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}
.discardBtn {
  border: none;
  text-align: center;
  width: 100px;
  height: 30px;
  margin: 0 20px 20px 5px;
  padding: 5px 0px;
  border-radius: 5px;
  color: #000;
  background: linear-gradient(#999999, #f0f0f0, #999999);
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}
.input label {
  margin-bottom: 4px;
  font-size: 12px;
  display: flex;
}
.input {
  width: 100%;
  padding-right: 10px;
}
.readOnly{
  opacity: .5;
}
.readOnly input{
  cursor: default;
}
.input.error , textarea.error{
  border-color: red !important;
  outline: none;
  color: red !important;
}
.close {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}


@media (max-width: 640px) {   
  .targetBtn,
  .resellBtn,
  .stopResaleBtn,
  .videoBtn,
  .giftBtn {
    font-size: 10px;
    line-height: 15px;
    width: 80px;
    height: 26px;
    margin: 2px;
  }
  .smThree, .smFour {
    padding: 2px !important;
  }  
  .fourcol {
    width: 100%;
  }
}
@media (max-width: 768px) { 
  .overdashbordtable {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .row {
    flex-direction: column;
  }
  .default {
    margin-left: 30px;
  }
  .formRowBetween_crypto {
    flex-direction: column;
  }
  .formRowBetween_gps {
    flex-direction: column;
  }
  .formRowEnd {
    flex-direction: row;
  }
  
  .formRowBetween {
    flex-direction: column;
  }
  .threecol {
    width: 100%;
  }
  .twocol {
    width: 100%;
  }
  .targeturl {
    width: 100%;
  }
  .rowUrl, .rowUrl1 { 
    width: 95%;
  }
}
@media (max-width: 1280px) {
}
@media (max-width: 1536px) { 
  .subrow {
    flex-direction: column;
  }
  .formRowBetween_top {
    flex-direction: column;
  }
  .formRowEnd {
    justify-content: space-between;
  }
}

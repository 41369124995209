.statisticsContainer {
  .totalProfitWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    margin-bottom: 8px;
    border: 1px solid gray;
    border-radius: 5px;
    box-shadow: rgba(88, 102, 126, 0.1) 0px 4px 24px, rgba(88, 102, 126, .12) 0px 1px 2px;
    @media only screen and (max-width: 1024px) {
      padding: 10px;
    }
    .totalProfitTitle {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
    .totalProfitContent {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }
  .performanceWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
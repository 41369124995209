.category-table {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  align-self: flex-start;

  &__header {
    font-size: 16px;
    background: rgba(0, 0, 0, 0.8);
    padding: 19px 70px;
    display: flex;
    border-top: 2px solid #025498;
    border-bottom: 1px solid #1f2024;
    align-items: center;
    font-weight: 600;
    width: 100%;
    .cell2 {
      width: 40%;
      text-align: right;
      flex-grow: 1;
    }
    .category-table__addcategory {
      // background: linear-gradient(#b7eaf6, #777fc4)//linear-gradient(#873f99, #311438);
      // color: black;
      height: 40px;
      width: 140px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      font-weight: bold;
      border: none;
      box-shadow: none;
      outline: none;
      display: inline-flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      border-radius: 5px;
    }
    .category-table__addcategory svg {
      margin-right: 5px;
    }
    .category-table__addcategory:hover {
      background: linear-gradient(#fbb212, #f48f33);
    }
    .category-table__addcategory:active {
      background: linear-gradient(#f48f33, #fbb212);
    }
  }
}
.dashboard-table__body-item.category-table__body.opacity {
  opacity: 0.3;
}
.dashboard-table__body-item.category-table__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 70px;

  .drag {
    cursor: grab;
    svg {
      margin-right: 10px;
    }
  }
  .cell1 {
    div {
      margin-bottom: 0;
    }
  }
  .cell2 {
    display: flex;
    width: 40%;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .btn {
    font-size: 14px;
    color: white;
    padding: 8px 0;
    background: black;
    font-weight: 600;
    text-decoration: navajowhite;
    cursor: pointer;
    width: 120px;
    text-align: center;
    margin-left: 20px;
    margin-right: 0 !important;
  }
}
@media (max-width: 640px) { 
}
@media (max-width: 768px) { 
  .category-table {
    min-height: 50vh;
  }
  .category-table__header {
    padding: 15px !important;
  }
  .category-table__body {
    padding: 15px !important;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1536px) { 
}
@media (max-width: 1660px) { 
}
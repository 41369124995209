.dropdown {
  position: relative;
  // display: flex;
}
/* Dropdown Content (Hidden by Default) */
.home-dropdown-sub {
  display: flex;
  flex-direction: row;
}
.home-dropdown-sub a {
  color: white;
  width: 45px;
  height: 45px;
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.home-dropdown-sub a:hover {background-color: #b056c7}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
.home-dropdown-content {
  display: none;
  position: absolute;
  background-color: #000000;
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  min-width: 50px;
  top: 47px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 4;
}

/* Links inside the dropdown */
// .home-dropdown-content a {
//   color: black;
//   width: 45px;
//   height: 45px;
//   text-decoration: none;
//   font-weight: 700;
//   text-align: center;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 5px;
// }
// /* Change color of dropdown links on hover */
// .home-dropdown-content a:hover {background-color: #44aeef}

/* Show the dropdown menu on hover */
.dropdown:hover .home-dropdown-content {
  display: block;
}


.drop-btn {
  background-color: transparent;
  color: black;
  margin: 17px 2px 5px 15px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.live-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dream-input {
  width: 100%;
  margin-left: 10px;
  padding-left: 5px;
  background: black;
  color: white;
  border: none;
  font-size: 16px;
  &:hover {
    border: none;
    outline: none;
  }
  &:active {
    border: none;
    outline: none;
  }
}
.span_txt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: black;
  height: 100%;
  width: 100%;
}
.claimed-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 20px;
}
.confirm-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-left: 5px;
}
.domain_input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 15px;
}
.h_line { 
  color: #aaa9a9;
  height: 100%;
  font-size: 30px;
  margin-left: 5px;
}
.recently-txt {
  font-size: 10px;
  letter-spacing: 2.5px;
  padding-left: 2.5px;
}
.recently-drop-txt {
  font-size: 10px;
  letter-spacing: 3px;
  padding-left: 3px;
}
.bookmark-txt {
  font-size: 10px;
  letter-spacing: 3.4px;
  padding-left: 3.4px;
  text-align: center;
}
.tooltip-search {
  width: fit-content;
}
.tooltip-icon-search {
  width: fit-content;
  .multi-line {
    text-align: left !important;
  }
}
.star-icon {
  position: absolute;
  z-index: 999;
  right: 10px;
  top: 9px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
}

.form_input_err {
  margin-right: 5%;
  color: red;
  text-align: right;
  font-size: 14px;
}
.home-search-container {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  
  form {
    text-align: center;
    display: flex;
    width: 100%;
    input {
      height: 100%;
    }
    button {
      height: 100%;
    }
  }
  .main-search-input {
    width: 100%;
    margin-left: 10px;
    border: none;
    font-size: 16px;
    margin-bottom: 5px;
    margin-right: 10px;
    &:hover {
      border: none;
    }
    &:active {
      border: none;
    }
  }
  .line_bar {
    color: #aaa9a9;
    height: 100%;
    font-size: 35px;
    margin-left: 5px;
  }
  .home-top-search {
    text-align: center;
    transition-delay: 100ms;
    border-radius: 50px;
    display: flex;
    // width: 57%;
    height: 50px;
    border: 1px solid #969999;
    position: relative;
    margin-right: -50px;
    background: black;

    // text-align: center;
    // border-radius: 50px;
    // display: flex;
    width: 100%;
    // height: 50px;
    // border: 1px solid #969999;
    // position: relative;
    .hashtagPrefix {
      color: black;
      position: absolute;
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      margin: 5px 0px 0px 15px;
    }
    .inputTag {
      border-radius: 50px 0 0 50px;
      border: none;
      outline: none;
      padding: 0 10px 0 40px !important;
      font-size: 15px;
      height: 48px;
      width: 100%;
    }
    // input {
    //   border-radius: 50px 0 0 50px;
    //   border: none;
    //   outline: none;
    //   padding: 0 40px;
    //   font-size: 18px;
    //   width: 100%;
    // }
    .btnTag {
      width: 170px;
      border-radius: 0 50px 50px 0;
      border: none;
      outline: none;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 540px) {
        width: 120px;
      }
      .search-btn {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 540px) {
          font-size: 12px;
        }
      }
    }
    
    button:hover {
      background: linear-gradient(#fbb212, #f48f33);
    }
    button:active {
      background: linear-gradient(#f48f33, #fbb212);
    }
    .error {
      font-size: 14px;
      position: absolute;
      left: 20%;
      color: white;
      background: rgba(0, 0, 0, 0.9);
      width: 60%;
      padding: 15px;
      margin-top: 20px;
      border-radius: 5px;
    }
  }
}
.test {
  color: linear-gradient(#9a12fb, #5812fb) linear-gradient(#7cbaf5, #3694eb);
}
@media only screen and (max-width: 1366px) {
  .domainsearchinp {
    form {
      input {
        //padding-left: 15px;
        //padding-right: 15px;
        //font-size: 14px;
      }
      button {
        // font-size: 12px;
        // padding-right: 10px;
        // width: 165px;
      }
    }
  }
}
.optionSearchEngin {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  form {
    text-align: center;
    display: flex;
    width: 100%;
    input {
      height: 100%;
    }
    button {
      height: 100%;
    }
  }
  
  .top-search {
    text-align: center;
    transition-delay: 100ms;
    border-radius: 50px;
    display: flex;
    width: 57%;
    border: 1px solid #969999;
    position: relative;
    margin-right: -50px;
    background: white;
    .hashtagPrefix {
      color: black;
      position: absolute;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin: 5px 0px 5px 15px;
    }
    .inputTag {
      border-radius: 50px 0 0 50px;
      border: none;
      outline: none;
      padding: 0 5px 0 35px !important;
      font-size: 14px;
      height: 38px;
      width: 100%;
    }    
    button:hover {
      background: linear-gradient(#fbb212, #f48f33);
    }
    button:active {
      background: linear-gradient(#f48f33, #fbb212);
    }
    .error {
      font-size: 14px;
      position: absolute;
      left: 20%;
      color: white;
      background: rgba(0, 0, 0, 0.9);
      width: 60%;
      padding: 15px;
      margin-top: 20px;
      border-radius: 5px;
    }
  }
  .option-top-search {
    text-align: center;
    transition-delay: 100ms;
    border-radius: 50px;
    display: flex;
    width: 65vw;
    margin-right: 5px;
    border: 1px solid #969999;
    position: relative;
    background: white;
    .hashtagPrefix {
      color: black;
      position: absolute;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin: 5px 0px 5px 15px;
    }
    .inputTag {
      border-radius: 50px 0 0 50px;
      border: none;
      outline: none;
      padding: 0 5px 0 35px !important;
      font-size: 14px;
      height: 40px;
      width: 100%;
    }
    .btnTag {
      width: 170px;
      border-radius: 0 50px 50px 0;
      border: none;
      outline: none;
      font-size: 20px;
      color: black;
      font-weight: bold;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 540px) {
        width: 120px;
      }
      .search-btn {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 540px) {
          font-size: 12px;
        }
      }
    }
    
    button:hover {
      background: linear-gradient(#fbb212, #f48f33);
    }
    button:active {
      background: linear-gradient(#f48f33, #fbb212);
    }
    .error {
      font-size: 14px;
      position: absolute;
      left: 20%;
      color: white;
      background: rgba(0, 0, 0, 0.9);
      width: 60%;
      padding: 15px;
      margin-top: 20px;
      border-radius: 5px;
    }
  }
  .dropbtn {
    background-color: transparent;
    color: black;
    margin: 13px 2px 8px 15px;
    font-size: 16px;
    font-weight: 700;
    border: none;
    cursor: pointer;
  }
  .left-icons {
    display: flex;
    flex-direction: column;
    margin: 5px 10px;
    cursor: pointer;
  }
  .err-msg {
    margin-right: 5%;
    color: #ff5e00;
    text-align: right;
    font-size: 14px;
    margin-top: -20px;
  }
  .option-wrapper {
    display: flex;
    flex-direction: row;    
    align-items: center;
    justify-content: center;
    padding: 0 0 0 30px;
    width: fit-content;
  }
  .hashtagTxt {
    font-size: 60px;
    font-weight: 700;
  }
  .option-title-div {
    display: flex;
    flex-direction: column;    
    margin: 0 5px;
    text-align: end;
  }
  .option-title {
    font-size: 30px;
    font-weight: 700;
  }
  .introduction-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    font-size: 14px;
    white-space: nowrap;
    @media only screen and (max-width: 1024px) {
      flex-direction: row;
      padding: 0;
      font-size: 11px;
      margin: 10px 0;
    }
    @media only screen and (max-width: 420px) {
      flex-direction: column;
    }
  }
  .mSpace {
    margin: 0 2px;
  }
  .search-engine-txt {
    width: 100%;
    text-align: end;
    font-size: 15px;
    font-weight: 700;
    background: linear-gradient(#ff9101, #fae08a);
    background-clip: text;
    color: transparent;    
    -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
    -webkit-mask-size: 200%;
    animation: shine 1.5s linear infinite;
  }
  .search-engine-txt-l {
    width: 100%;
    text-align: end;
    font-size: 15px;
    font-weight: 700;
    background: linear-gradient(#63f051, #338f27);
    background-clip: text;
    color: transparent;    
    -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
    -webkit-mask-size: 200%;
    animation: shine 1.5s linear infinite;
  }
  .streaming-container {
    position: absolute;
    z-index: 2;
    top: 45px;
    width: 100%;
    background: transparent;
  }
  .live-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .dream-input {
    width: 100%;
    margin-left: 10px;
    border: none;
    font-size: 16px;
    &:hover {
      border: none;
    }
    &:active {
      border: none;
    }
  }
  .span_txt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  .claimed-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-left: 20px;
  }
  .confirm-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-left: 5px;
  }
  .domain_input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 15px;
  }
  .h_line { 
    color: #aaa9a9;
    height: 100%;
    font-size: 30px;
    margin-left: 5px;
  }
  .recently-txt {
    font-size: 10px;
    letter-spacing: 2.5px;
    padding-left: 2.5px;
  }
  .recently-drop-txt {
    font-size: 10px;
    letter-spacing: 3px;
    padding-left: 3px;
  }
  .bookmark-txt {
    font-size: 10px;
    letter-spacing: 3.4px;
    padding-left: 3.4px;
    text-align: center;
  }
  .tooltip-search {
    width: fit-content;
  }
  .tooltip-icon-search {
    width: fit-content;
    .multi-line {
      text-align: left !important;
    }
  }
  .star-icon {
    position: absolute;
    z-index: 999;
    right: 10px;
    top: 9px;
    cursor: pointer;
    :hover {
      transform: scale(1.1);
    }
  }
}
.dropdown {
  position: relative;
  // display: flex;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-sub {
  display: flex;
  flex-direction: row;
}
.dropdown-sub-d {
  display: flex;
  flex-direction: row;
}
.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  min-width: 50px;
  top: 40px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 4;
}

/* Links inside the dropdown */
.dropdown-sub-d a {
  color: white;
  width: 45px;
  height: 45px;
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.dropdown-sub a {
  color: black;
  width: 45px;
  height: 45px;
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

/* Change color of dropdown links on hover */
.dropdown-sub a:hover {background-color: #98d6f5}
.dropdown-sub-d a:hover {background-color: #b056c7}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

@keyframes shine {
  from { -webkit-mask-position: 150%; }
  to { -webkit-mask-position: -50%; }
}



@media (max-width: 640px) {   
}
@media (max-width: 768px) { 
  .optionSearchEngin {
    flex-direction: column !important;
  }
  .option-top-search {
    width: 96% !important;
    margin-bottom: 10px;
  }
  .left-icons {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .optionSearchEngin {
    flex-direction: column !important;
    width: 100% !important;
  }
  .option-top-search {
    width: 96% !important;
    margin-bottom: 10px;
  }
  .left-icons {
    display: none !important;
  }
}
@media (max-width: 1280px) { 
}
@media (max-width: 1536px) { 
}
  

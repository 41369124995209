.portfolioContainer {
  width: 100%;
  // z-index: 0;
  // height: calc(100vh - 177px);

  .portfolioHeaderContainer {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 768px) {      
      margin-top: 20px;
    }
    .titleWrapper {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 28px;
        font-weight: 700;
        @media only screen and (max-width: 640px) {      
          font-size: 20px;
        }
      }
      .description {
        margin: 12px 0;
        @media only screen and (max-width: 640px) {      
          font-size: 14px;
        }
      }
    }
  }
  .portfolioBodyContainer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      justify-content: start;
      flex-direction: column;
    }
  }
}

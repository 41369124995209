.coinChartContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  width: 45% !important;
  @media only screen and (max-width: 1536px) {
    width: 100% !important;
  }
  .itemContent {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .c-line {
    height: 80px;
    border: 1px solid;
    width: 1px;    
  }
  .coinSpinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .icon {
    img {
      margin: 10px;
      width: 50px;
    }
  }
  .mainContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      height: 80px;
      width: 100%;
      @media only screen and (max-width: 420px) {
        font-size: 9px;
      }
      .topSetting {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 35px 0 0 0;
        z-index: 99;
      }
      .bottomChart {
        height: 80px;
        // min-width: 15vw;
        width: 100%;
      }
      .coinleft {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        margin-left: 3px;
      }
      .coinright {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin-right: 25px;
      }
      .view {
        margin-right: 5px;
      }
      .h-line {
        margin: 0 10px;
      }
      .uniswap {
        color: red;
        font-weight: 600;
      }
      .binance {
        color: #ffd900;
        font-weight: 600;
      }
      .binanceicon {
        margin-right: 5px;
        margin-top: 3px;
        cursor: pointer;
      }
      .selBox {
        border: none;
        cursor: pointer;
      }
      .percent {
        font-weight: 600;
      }
    }
  }
}
.apexcharts-canvas {
  margin: -25px 0px 0px -20px;
}

.modalContent {
  width: 720px;
  padding: 25px 30px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #03569c;
  color: white;

  .headerContent {
    display: flex;
    justify-content: space-between;
    height: 62px;
  }
  .bodyContent {
    width: 100%;
    min-height: 330px;
    margin-top: 24px;
    button {
      svg {
        position: absolute;
        right: 10px;
        font-size: 20px;
      }
    }
    .labelText {
      color: #919599;
      font-size: 12px;
      margin-bottom: 8px;
    }
    .walletWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin-bottom: 24px;
      .walletAddressWrapper {
        width: 64%;
        margin-top: -4px;
      }
    }
    .tokenWrapper {
      display: flex;
      justify-content: space-between;
      .tokenSelectionWrapper {
        width: 35%;
        display: flex;
        flex-direction: column;
        position: relative;
        .selectedTokenWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            margin-right: 12px;
            border-radius: 50%;
          }
        }
      }
      .customTokenWrapper {
        width: 64%;
        margin-top: -4px;
      }
    }

    .tokenDetailWrapper {
      margin-top: 24px;
      text-align: center;
      .labelText {
        font-size: 20px;
        font-weight: 600;
        margin-left: 24px;
      }
    }

    .createAssetsBtn {
      margin-top: 48px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

.main-search {
  text-align: center;
  position: relative;
  margin-top: 150px;
  transition-delay: 100ms;
  @media only screen and (max-width: 640px) {
    // margin-top: 0px;
  }
  .hs-d {
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 5px;
    text-shadow: 2px 0 0 #bbb, -2px 0 0 #bbb, 0 2px 0 #bbb, 0 -2px 0 #bbb, 1px 1px #bbb, -1px -1px 0 #bbb,
      1px -1px 0 #bbb, -1px 1px 0 #bbb;
    color: #000;
    @media only screen and (max-width: 768px) {
      font-size: 50px;
    }  
    @media only screen and (max-width: 640px) {
      font-size: 40px;
    }
  }
  .hs {
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 5px;
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000,
      1px -1px 0 #000, -1px 1px 0 #000;      
    @media only screen and (max-width: 768px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 640px) {
      font-size: 40px;
    }
  }
  .d-hs-d {
    font-size: 28px;
    font-weight: bolder;
    margin: 12px 0px;
    text-shadow: 2px 0 0 #bbb, -2px 0 0 #bbb, 0 2px 0 #bbb, 0 -2px 0 #bbb, 1px 1px #bbb, -1px -1px 0 #bbb,
      1px -1px 0 #bbb, -1px 1px 0 #bbb;
    color: #000;    
    @media only screen and (max-width: 768px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 640px) {
      font-size: 16px;
    }
  }
  .d-hs {
    font-size: 28px;
    font-weight: bolder;
    margin: 12px 0px;
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000,
      1px -1px 0 #000, -1px 1px 0 #000;  
    @media only screen and (max-width: 768px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 640px) {
      font-size: 16px;
    }
  }
  &__overaction {
    border-radius: 50px;
    display: flex;
    width: 1000px;
    height: 60px;
    border: 1px solid #969999;
    position: relative;
    svg {
      position: absolute;
      left: 15px;
      top: 23px;
    }
    .hashtagPrefix {
      color: black;
      position: absolute;
      font-size: 24px;
      font-weight: 700;
      top: 14px;
      left: 17px;
    }
    input {
      border-radius: 50px 0 0 50px;
      border: none;
      outline: none;
      padding: 0 40px;
      font-size: 18px;
      width: 100%;
    }
    button {
      width: 150px;
      border-radius: 0 50px 50px 0;
      border: none;
      outline: none;
      font-size: 20px;
      color: black;
      font-weight: bold;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    button:hover {
      background: linear-gradient(#fbb212, #f48f33);
    }
    button:active {
      background: linear-gradient(#f48f33, #fbb212);
    }
  }
  .error {
    font-size: 14px;
    position: absolute;
    left: 20%;
    color: white;
    background: rgba(0, 0, 0, 0.9);
    width: 60%;
    padding: 15px;
    margin-top: 20px;
    border-radius: 5px;
  }
}

// @media only screen and (max-width: 1366px) {
//   .main-search {
//     &__overaction {
//       width: 900px;
//     }
//   }
// }
.cn_input__18ZfH.hashtagselect {
  position: absolute;
  width: 50px;
  left: 5px;
  top: 8px;
  padding-right: 0;
}
.cn_input__18ZfH.hashtagselect label {
  margin-bottom: 0;
}
.cn_input__18ZfH.hashtagselect .select {
  border-color: transparent;
}
.cn_input__18ZfH.hashtagselect .select select {
  color: black;
  background: transparent;
  font-size: 20px;
  font-weight: bold;
}
.cn_input__18ZfH.hashtagselect .select__arrow {
  border-color: black transparent transparent transparent;
  display: none;
}
.cn_input__18ZfH.hashtagselect .select:hover .select__arrow {
  border-color: black transparent transparent transparent;
}
.cn_input__18ZfH.hashtagselect .select:focus .select__arrow {
  border-color: black transparent transparent transparent;
}
.cn_input__18ZfH.hashtagselect .select:active .select__arrow {
  border-color: black transparent transparent transparent;
}


.introduction-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  div {
      width: 100%;      
      line-height: 100%;
      display: flex;
      justify-content: center;
      :hover {
        cursor: pointer;
      }
      
      .intro-text {
          overflow-wrap: anywhere;          

          .single-letter {
              display: inline-block;
              transform-origin: 50% 84%;              
          }
          .hovered {
              animation-name: stretch;
              animation-duration: 0.15s;
              animation-timing-function: linear;
              animation-fill-mode: forwards              
          }
          .unhovered {
              animation-name: stretch-back;
              animation-duration: 0.25s;
              animation-timing-function: linear;
          }
      }
  }
}

@keyframes stretch {
  from,
  0% {  
  }
  40% {
      transform: scale(1, 1.5);
  }
  100% {
      transform: scale(1, 2);
  }
}

@keyframes stretch-back {
  from,
  0% {
      transform: scale(1, 1.8);
  }
  33% {
      transform: scale(1, 1.5);
  }
  66% {
      transform: scale(1, 1.2);
  }
  100% {
      transform: scale(1, 1);
  }
}


.emailsend-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    font-size: 18px;
}
.to-email {
    height: 38px;
    padding: 5px;
    font-size: 18px;
    margin-bottom: 5px;
}
.email-title {
    margin-top: 2px;
    margin-bottom: 2px;
    height: 38px;
    padding: 5px;
    font-size: 18px;
}
.invite-form {
    padding: 10px 20px;
}
.invite-friend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
}
.desc-container {
    width: 30%;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}
.email-desc {
    font-size: 12px;
    margin-top: 10px;
}
.txtareaitem {
    width: 100%;
    margin: 0 20px;
    border-radius: 5px;
    padding: 5px;
    min-height: 50px;
    @media only screen and (max-width: 1660px) {
        margin: 0 10px;
        width: 90%;
    }
    @media only screen and (max-width: 1280px) {
        margin: 0 5px;
        width: 60%;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-top: 5px;
    }
}
.btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 1280px) {        
        flex-direction: column;
    }
    @media only screen and (max-width: 768px) {
        flex-direction: row;
        margin-top: 5px;
        justify-content: space-between;
        width: 100%;
    }
}
.formBtn {
    border: none;
    outline: none;    
    min-width: 120px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0 10px;
    cursor: pointer;
    @media only screen and (max-width: 1660px) {
        margin: 2px 5px;
    }
    @media only screen and (max-width: 1280px) {
        font-size: 13px;
        padding: 5px;
        margin: 2px 2px;
    }
}

.tableBtn {
    border: none;
    outline: none;    
    min-width: 90px;
    font-size: 13px;
    font-weight: 700;
    border-radius: 3px;
    padding: 5px 10px;
    margin: 2px 5px;
    cursor: pointer;
    background: linear-gradient(rgb(165, 165, 165), rgb(243, 243, 243), rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgb(243, 243, 243), rgb(165, 165, 165));
}

.invite-table {
    margin: 5px 0;
}
.action-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
}
.modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  position: fixed;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s linear;

  &-content {
    width: 30vw;
    padding: 25px 30px;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid #03569c;
    color: white;
    border-radius: 10px;
    
    &-header {
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
      font-weight: 700;
      padding-bottom: 20px;

      .header-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .header-description {
        font-size: 12px;
        line-height: 22px;
        color: #919599;
        font-weight: 500;
        .credit-amount {
          color: #ffffff;
          font-weight: 700;
        }
      }
      .modal-close {
        margin-right: -15px;
        cursor: pointer;
      }
    }

    .transactionView {
      margin: 20px 0;
      .withdrawCredit {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        margin-bottom: 20px;
        .paypalSymbolImg {
          padding-top: 4px;
        }
      }
      .transactionFee {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 22px;
        font-weight: 500;
      }
    }

    .divide-line {
      width: 100%;
      height: 1px;
      background-color: #35495e;
    }
    .total-withdraw-amount {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
      font-size: 12px;
      line-height: 22px;
      font-weight: 500;
      .totalAmount {
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
      }
    }

    .paypalAddressInput {
      display: flex;
      justify-content: center;
      .login-inp {
        input {
          height: 43px;
          font-size: 16px;
          padding: 0 25px;
          width: 410px;
          margin-top: 10px;
          margin-bottom: 20px;
          background-color: transparent;
          border-bottom: 1px solid white;
          color: white;
        }
      }
    }

    .transaction-description {
      display: flex;
      justify-content: center;
      font-size: 12px;
      line-height: 22px;
      color: #919599;
      font-weight: 500;
      text-align: center;
      margin: 20px 0;
    }

    .confirmMessage {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .messageText {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 12px;
      }
      .description {
        font-size: 12px;
        line-height: 22px;
        color: #919599;
        font-weight: 500;
        text-align: center;
        margin-bottom: 12px;
      }
    }
  }
}

.withdrawBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.withdrawBtn .btn {
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;
  background-color: #0093fc;
  color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  height: 40px;
}
.withdrawBtn .btn:hover {
  background: linear-gradient(#fbb212, #f48f33);
}

.withdrawBtn .btn:disabled {
  background: #606061;
  cursor: default;
}

.modal.animated-enter {
  opacity: 0.01;
}
.modal.animated-enter-active {
  opacity: 1;
}

.modal.animated-exit {
  opacity: 0;
}
.modal.animated-exit-active {
  opacity: 0;
}

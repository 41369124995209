.shift-bottom-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 5px 0px 5px;
}
.trans-table-div {
    height: 200px;
    overflow-y: scroll;
    margin: 0 -12px;
    padding: 0 10px;
}
.shift-loader-spinner {    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
}
.trans-table {
    background-color: transparent !important;
    margin-top: 25px;
}
.trans-time {
    color: gray;
    font-size: 12px;
    margin-top: 5px;
}
.trans-type {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: gray;
    font-size: 12px;
    margin-top: 5px;
}
.trans-proj {
    text-align: end;
}

.transaction-tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    border-bottom-style: dashed;
    margin-bottom: 10px;
}
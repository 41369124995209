.holo-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    &__main {
        padding: 20px 30px 20px 50px;
        z-index: 0;
        width: 100%;
        @media only screen and (max-width: 430px) {
            padding: 30px;
        }
    }
}
.background-img {
    position: absolute;    
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
.holo-txt {
    margin-left: -12px;
    max-width: 350px;
}
.shadow-blue {
    color: white;
    font-weight: 900;
    text-shadow: 3px 3px 20px #3d3df8, -2px 1px 30px #3d3df8;
}
.game-txt {
    letter-spacing: 5px;
}
.left-holo {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // z-index: 1;
    align-items: flex-end;
    // min-height: 240px;
    // margin-right: 40px;
    padding-right: 0px;
    @media only screen and (max-width: 430px) {
        padding-right: 30px;
        // margin-right: 50px;
    }
}

@keyframes anim-opacity {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

.a-container1 {
    display: flex;
    flex-direction: column;
    min-height: 180px;
    position: relative;
}
.a-container2 {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.a-topContainer {
    display: flex;
    flex-direction: row;
    // position: relative;
    width: 100%;
    // margin-bottom: 45px;
}
.a-return-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 40%;
    height: 176px;
    padding: 10px 20px;
    font-size: 13px;
    @media only screen and (max-width: 1366px) {
        width: 50%;        
        height: 150px;
    }
    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
    @media only screen and (max-width: 420px) {
        padding: 5px 10px;
    }
}

.a-diagramContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 30px;
}
.diagram_img {
    margin: 20px;
    width: 85%;
    @media only screen and (max-width: 1366px) {
        margin-top: 30px;
    }    
    @media only screen and (max-width: 1280px) {
        margin-top: 50px;
    }
    @media only screen and (max-width: 1024px) {
        margin-top: 150px;
        width: 90%;
    }
    @media only screen and (max-width: 768px) {
        width: 95%;
    }
    @media only screen and (max-width: 420px) {
        margin: 150px 0 20px 2px;
    }
}

.a-return-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.a-return-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
.a-return-desc {
    margin-top: 10px;
    font-size: 12px;
}
.a-return-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media only screen and (max-width: 1280px) {
        font-size: 12px;
    }
    @media only screen and (max-width: 1024px) {
        font-size: 10px;
    }
    @media only screen and (max-width: 420px) {
        font-size: 10px;
    }
}
.a-return-right-item {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 0px;
    margin: 0 2px;
    cursor: pointer;
}
.a-external-icon {
    width: 14%;
    padding: 17px 0 0 0;
    justify-content: center;
    display: flex;
    margin-left: 10px;
}
.a-button-copy-link {
    margin-top: 15px;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: bold;
    color: black;
    border-radius: 5px;
    padding: 8px 0px 8px 10px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif !important;
    background: linear-gradient(#873f99, #311438);
    cursor: pointer;
    white-space: nowrap;
    @media only screen and (max-width: 1024px) {
        font-size: 11px;
    }
    svg {
        margin-right: 12px;
    }
}
.a-error-msg {
    position: absolute;
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
}
.token-desc {
    margin: 10px 0 15px 20px;
    position: absolute;
    bottom: -5px;
    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }
}
.overline {
    font-weight: bold;
    text-decoration: overline;
}
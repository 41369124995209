.tokenWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  padding: 8px 4px;
  box-sizing: border-box;
  &:hover {
    background-color: #0093fc2c;
    cursor: pointer;
  }
  .tokenName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .tokenIcon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      border-radius: 50%;
    }
  }
}
.footer_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footer {
  width: 100%;
  height: 50px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  margin: 0 auto;
  z-index: 1;
  position: fixed;
  bottom: 0px;
  ul {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    li {
      list-style-type: none;
      span {
        font-size: 16px;
        color: #c8daec;
        text-decoration: none;
        cursor: pointer;
        padding-left: 15px;
        padding-right: 15px;
      }
      span:hover {
        text-decoration: underline;
      }
    }
  }
  .f_menu {
    text-align: center;
    font-size: 10px;
    margin-top: 20px;
    color: #c8daec;
  }
  .f_menu a {
    color: #c8daec;
    margin-right: 10px;
  }
}

.footer-d {
  width: 100%;
  height: 50px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: rgba(209, 207, 207, 0.7);
  margin: 0 auto;
  z-index: 1;
  position: fixed;
  bottom: 0px;
  ul {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    li {
      list-style-type: none;
      span {
        font-size: 16px;
        color: #000;
        text-decoration: none;
        cursor: pointer;
        padding-left: 15px;
        padding-right: 15px;
      }
      span:hover {
        text-decoration: underline;
      }
    }
  }
  .f_menu {
    text-align: center;
    font-size: 10px;
    margin-top: 20px;
    color: #000;
  }
  .f_menu a {
    color: #000;
    margin-right: 10px;
  }
}
.light_icon {
  width: 300px;
}

@media (max-width: 640px) { 
  .footer, .footer-d {
    width: 100%;
    padding: 0 4px;
    ul li span {
      font-size: 11px !important;
      display: flex;
      align-items: center;
      justify-content: center !important;
    }
  }
}
@media (max-width: 1024px) {
  .footer, .footer-d {
    width: 100%;
    padding: 0 4px;
    ul li span {
      font-size: 14px;
      padding: 0 5px;
    }
  }
}
@media (max-width: 1280px) { 
}
@media (max-width: 1536px) { 
}
.withdrawForm {
  background: #00000090;
  border-top: 2px solid #025498;
  padding: 14px;
  /* width: calc(100% / 3); */
  position: relative;
}
.withdrawForm_l {
  background: #ffffff90;
  border-top: 2px solid gray;
  padding: 14px;
  /* width: calc(100% / 3); */
  position: relative;
}
form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.formLabel {
  width: 100%;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 22px;
  color: #2ec814;
  font-weight: 500;
  display: flex;
}
.formLabel span {
  color: #caced2;
}
.payInp {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.payInp .input label {
  margin-bottom: 0px;
}
.payInp .withdrawInp {
  position: relative;
  min-width: 50%;
  margin-right: 10px;
}

.withdrawInp .maxBtn {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-28%);
  background-color: transparent;
  border: none;
}

.withdrawInp .maxBtn:hover {
  color: #fbb212 !important;
  cursor: pointer;
}

.payInp .btnCont {
  display: flex;
}
.payGoBtn {
  border: none;
  outline: none;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  line-height: 70px;
  font-weight: 600 !important;
  text-align: center;
  margin: 0 1px;
  padding: 0px;
  width: 90px;
  height: 40px;
  border-radius: 5px;
}
.payGoBtn:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.payGoBtn:disabled {
  cursor: default;
  transform: scale(1);
}
.paypal {
  position: absolute;
  width: 50px;
  top: 0;
  left: 65px;
}

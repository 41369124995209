
.root {
  position: relative;
  width: 100%;
}
.baseButtonMixin {
  background: none;
  border: none;
  padding: 0;
}

.nickname {
  display: flex;
  border: 1px solid white;
  height: 38px;
  font-size: 18px;
  padding: 0 10px;
  color: white;
  width: 100%;
  outline: none;
}
.input-div {
  width: '200vw';
  height: '200vh';
  background: 'transparent';
  position: 'fixed';
  z-index: 0;
  top: 0;
  left: 0;
}
.sub-contain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
// .domainname-container {
//   background: rgba(0, 0, 0, 0.8);
//   color: #fff;
//   padding: 8px 0;
//   list-style-type: none;
//   width: 100%;
//   position: absolute;
//   top: 100%;
//   left: 0;
//   border: 1px solid #b6c1ce;
//   border-radius: 2px;
//   // margin-top: 2px;
//   box-sizing: border-box;
//   max-height: 280px;
//   overflow-y: auto;
//   z-index: 1;
// }
.count-icon {
  background: #08bfdd;
  border-radius: 50%;
  position: absolute;
  font-size: 10px;
  padding: 2px;
  height: 16px;
  line-height: 12px;
  min-width: 16px;
  text-align: center;
  display: inline-block;
  color: #fff;
  top: 0px;
  right: -5px;
}
.item {
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
}

.itemdiv {
  width: 100%;
  line-height: 20px;
  text-align: left;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  &:hover {
    outline: none;
    color: #fbb212;
    background: transparent;
    cursor: pointer;
  }
}

.nickName {
  max-width: 68%;
  word-break: break-all;
}

.levelStar {
  display: flex;
  color: #000;
  font-weight: 700;
  display: flex;
  align-items: center;
  background: #fbb212;
  border-radius: 3px;
  padding: 5px;
  font-size: 14px;
}

.logo_img {
  width: 233px;
  z-index: 1;  
  @media only screen and (max-width: 768px) {
    width: 300px;
    margin: 20px 0 10px 0;
  }
  @media only screen and (max-width: 580px) {
    margin: 40px 0 10px 0;
  }
}
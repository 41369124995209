// .capstable {
    .msg-time {
        color: gray;
        font-size: 12px;
        padding-top: 3px;
    }
    .dream-td {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0px;
        margin: 10px 0;
    }  
// }
.capslock-bottom-item {
    overflow-y: scroll;
    width: 102%;
}
.email-title {
    font-size: 16px;
    font-weight: 600;
}
.email-time {
    font-size: 12px;
    color: gray;
}
.categoryContainer {
  width: 280px;
  min-width: 280px;
  height: 112px;
  box-shadow: 0 0 8px rgba(49, 67, 164);
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid #5d90dc;
  border-top: 2px solid #008bff;
  padding: 15px;
  margin: 20px 0;
  box-sizing: border-box;
  
  .Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .domainName {
      font-size: 17px;
      line-height: 22px;
      color: #ffffff;
      font-weight: 700;
      margin-right: 8px;
      overflow: hidden;
      white-space: nowrap; /* Don't forget this one */
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .availability {
      font-size: 9px;
      color: #faac18;
      font-weight: 400;
      text-align: center;
      border: 1px solid #f8a221;
      border-radius: 2px;
      padding: 0 4px;
    }
  }
  .itemBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .popularityContainer {
      .popularityHeader {
        font-size: 8px;
        line-height: 22px;
        color: #95999c;
        font-weight: 400;
      }
      .popularityBody {
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
        .volume {
          display: flex;
          flex-direction: column;
          margin-left: 8px;
          .volumeNumber {
            font-size: 12px;
            color: #ffffff;
            font-weight: 700;
          }
          .highVolume {
            font-size: 8px;
            color: #95999c;
            font-weight: 400;
          }
        }
      }
    }
    .cartBtn,
    .disabledCardBtn {
      width: 94px;
      height: 44px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 6px;
      font-size: 12px;
      color: #fcb60f;
      background-color: rgba(253, 188, 8, 0.1);
      font-weight: 700;
      box-shadow: 0 0 8px rgba(253, 189, 8), 0 0 8px rgba(254, 191, 6);
      .cartPrice {
        margin: 0 8px;
      }
      .anualUnit {
        margin-bottom: 8px;
        font-size: 10px;
      }
    }
    .disabledCardBtn {
      color: #fc330f;
      box-shadow: 0 0 8px rgb(253, 57, 8), 0 0 8px rgba(254, 55, 6);
    }
  }
}

.timeOptionTab {
    display: flex;
    justify-content: space-between;
    .timeOption {
      align-items: center;
      background: transparent;
      border: 0px;
      border-radius: 24px;
      display: inline-flex;
      cursor: pointer;
      -webkit-box-pack: center;
      justify-content: center;
      font-weight: 600;
      width: auto;
      height: 32px;
      font-size: 12px;
      padding: 0px 16px;
      line-height: 18px;
    }
    .active {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgb(50, 53, 70);
      color: #ffffff;
    }
  }

.apexcharts-tooltip {
    background: #f3f3f3;
    color: orange;
  }
.tokenListWrapper {
  position: absolute;
  top: 68px;
  left: 0px;
  max-height: 320px;
  overflow-y: auto;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid #03569c;
  z-index: 1;
}
.claim-title {
    font-size: 18px;
    font-weight: 600;
}
.btn-go {
    background: linear-gradient(#08d108, #026b02);
    border-radius: 5px;
    padding: 5px 10px;
    width: 70px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
}
.btn-visit {
    background: linear-gradient(#ffb922, #d69301);
    border-radius: 5px;
    padding: 5px 10px;
    width: 70px;
    text-align: center;
    color: #000;
    font-weight: 700;
    cursor: pointer;
}
.btn-claim {
    background: linear-gradient(#08d108, #026b02);
    border-radius: 5px;
    padding: 5px 10px;
    width: 80px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.claim-bottom-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 280px;
    padding: 0 0 5px 5px;
    overflow-y: auto;
}
.claim-title {
    color: gray;
    padding: 15px 0px 0px 15px;
}
.loader-spinner {    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.f-weight {
    font-weight: 700;
}
.claim-table {
    background-color: transparent !important;
    margin-top: 15px;
}
.claim-table tbody tr {
    &:hover {
        background: #5a5a5a71;
    }
}
.claim-table td {
    padding-top: 6px;
    text-align: start;
    border-bottom: 1px solid #686767c7;
    border-bottom-style: dashed;
}
.claim-table th {
    color: gray;
    text-align: start;
    border-bottom: 2px solid gray;
}
.domain-edit__header {
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    border-top: 2px solid;
    margin-bottom: 15px;
}
.domain-edit__header-tit {
    padding-left: 25px;
    height: 60px;
    align-items: center;
    font-size: 18px;
    display: flex;
}
.domain-edit__header-tit_second {
    height: 50px;
    align-items: center;
    display: flex;
    font-size: 18px;
    text-align: center;
    position: relative;
    font-weight: bold;
    border-top: 1px solid #32363b;
    border-bottom: 1px solid #32363b;
}
.domain-edit__header-tit_second p {
    padding-left: 25px;
    height: 50px;
    align-items: center;
    display: flex;
    width: 100%;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  .headerContainer {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 35px 0;
    padding: 0 15px;
    @media only screen and (max-width: 1660px) {
      padding: 0 10px;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      margin: 15px 0;
      padding: 0 15px;
    }
    @media only screen and (max-width: 640px) {
      padding: 0 5px;
    }
  }
  
  .twoContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;
    width: calc(100vw - 230px);
    @media only screen and (max-width: 1536px) {
      margin-left: 17px;
      flex-direction: column;
    }
    @media only screen and (max-width: 768px) {
      margin-left: 0px;
      width: 100%;
    }
  }
  .headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25px;
    width: 60%;
    height: 64px;
    padding: 5px;    
    @media only screen and (max-width: 1660px) {
      margin-left: 10px;
    }
    @media only screen and (max-width: 1536px) {
      width: 100%;
      margin: 10px 0 0 0;
    }
    @media only screen and (max-width: 1024px) {
      height: 100px;
    }
    .header__right {
      align-items: center;
      display: flex;
      justify-content: space-between;
      
      .header-icon {
        margin-left: 20px;
        cursor: pointer;
        position: relative;
        &:hover {
          color: #000;
        }
        .menuIconContainer {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 14px;
          height: 14px;
          gap: 1px;
          box-shadow: 0 0 5px 3px #00adfe, 0 6px 20px 0 #0072fe;
          background: gray;
          svg {
            filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
          }
          .menuIconOverlay {
            width: 4px;
            height: 4px;
            background-color: #ffffff;
          }
        }
      }
    }
    .header-icon {
      display: flex;
      align-items: center;  
      text-decoration: none;    
      &:hover {
        font-weight: 700;
      }
    }
  }
  .headerContentUnlogin {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    z-index: 1;
    @media only screen and (max-width: 768px) {
      justify-content: center;
      margin-top: 10px;
    }
  }
  .hint {
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
    :hover {
      background: black;
      border-radius: 50%;
    }
  }

}

.header-search {
    display: flex;
    border-radius: 50px;
    height: 40px;
    border: 1px solid #bebdbd;
    margin-top: 3px;
    margin-left: 10px;
    input {
      border-radius: 50px 0 0 50px;
      border: none;
      outline: none;
      padding-left: 15px;
      font-size: 16px;
      width: 120px;
      background: transparent;
      color: #fff;
    }
    button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid transparent;
      outline: none;
      font-size: 14px;
      color: black;
      font-weight: bold;
      cursor: pointer;
      // text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
}
.wallet-btn {
  display: flex;
  justify-content: center;
  font-size: 12px;
  @media only screen and (max-width: 768px) {
    position: absolute;
    right: 5px;
    top: 55px;
  }
}
.wallet-btn1 {
  font-size: 12px;
  @media only screen and (max-width: 768px) {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
.wallet-btn-wrong {
  display: flex;
  justify-content: center;
  width: 170px;
}

@media (max-width: 640px) { 
}
@media (max-width: 768px) {    
}
@media (max-width: 1024px) {
}
@media (max-width: 1280px) { 
}
@media (max-width: 1536px) { 
}
.person_container {
    display: flex;    
    padding: 5px 20px;
}
.info_container {
    display: flex;
    flex-direction: column;
    width: 60%;
}
.pass_container {
    display: flex;
    flex-direction: column;
    width: 40%;
}
.person_input {
    display: flex;    
    width: 100%;
}
.inputTxt {
    margin: 0px 10px;
}
.btn_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
}
.sub_title {
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px;
}
.btn_save {
    border: none;
    text-align: center;
    width: 80px;
    height: 25px;
    margin: 0 10px;
    padding: 5px 0px;
    border-radius: 5px;
    color: #000;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}
.btn_discard {
    border: none;
    text-align: center;
    width: 80px;
    height: 25px;
    padding: 5px 0px;
    border-radius: 5px;
    color: #000;
    background: linear-gradient(#999999, #f0f0f0, #999999);
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}


@media (max-width: 640px) { 
    .person_container {
        margin-top: 10px;
        padding: 5px 20px 5px 0px;
    }
    .person_input {
        flex-direction: column;
    }
}
@media (max-width: 768px) { 
}
@media (max-width: 1024px) {
}
@media (max-width: 1280px) {    
    .person_container {
        flex-direction: column;   
    }
    .info_container {
        width: 100%;
    }
    .pass_container {
        width: 100%;
        margin-top: 10px;
    }
}
@media (max-width: 1536px) { 
}
.command_right {
    align-items: center;
    display: flex;    
    flex-direction: column;
    margin-top: 10px;
    width: 50px;
    @media only screen and (max-width: 1024px) {
      // width: 100%;
    }
    @media only screen and (max-width: 640px) {
      display: none;
    }
}
.command_left {
    align-items: center;
    display: flex;        
    width: 100%;  
    flex-direction: column;
}
.tab-swift {
  display: flex;
  width: 150%;
  @media only screen and (max-width: 1280px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.left-row {                
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; 
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%; 
  }
}
.left-row-three {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; 
  @media only screen and (max-width: 1280px) {
    flex-direction: column;
    width: 100%;
  }
}
.dashboard-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 10px;
    &__main {
        display: flex;
        flex-direction: column;
        border-top: 2px solid;
        padding: 10px 5px 10px 15px;
        justify-content: center;
        @media only screen and (max-width: 1660px) {
          padding: 10px;
        }
    }    
    .iconCart {
      position: relative;
      cursor: pointer;
    }
    .cartStic {
      background: rgb(250, 60, 59);
      border-radius: 50%;
      position: absolute;
      font-size: 10px;
      padding: 2px;
      height: 14px;
      line-height: 12px;
      min-width: 14px;
      text-align: center;
      display: inline-block;
      color: #fff;
      top: -5px;
      right: -8px;
    }
}
.paragrah {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-y: auto;
}
.para-1 {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}
.myCartDropdown {
    position: absolute;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    border-radius: 1px;
    box-shadow: 0 18px 50px rgba(0, 0, 0, 0.16);
    top: calc(100% + 9px);
    left: -243px;
    z-index: 15;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    width: 260px;
  
    &:before {
      content: '';
      position: absolute;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0L0 8H16L8 0Z' fill='white'/%3E%3C/svg%3E%0A");
      width: 16px;
      height: 8px;
      top: -8px;
      right: 0px;
    }
    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 15px;
      min-height: 48px;
      background: #f6f6f6;
      transition: background 0.1s;
  
      &:hover {
        background: #f6f6f6;
      }
  
      svg {
        margin-right: 20px;
      }
  
      span {
        font-size: 14px;
        line-height: 22px;
        color: #222222;
      }
    }
  }

@keyframes anim-opacity {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

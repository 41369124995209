.tab-bottom-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 5px 0px 5px;
}
.balance-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 10px;
    margin-bottom: 20px;
}
.gray-font {
    color: gray;
}
.tab-table-div {
    height: 130px;
    overflow-y: scroll;
    margin: 0 -12px;
    padding: 0 10px;
}
.tab-table {
    background-color: transparent !important;
    margin-top: 5px;
}
.tab-logo-img {
    width: 25px;
}
.tab-loader-spinner { 
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.tab-tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    border-bottom-style: dashed;
    margin-bottom: 10px;
    padding: 5px;
}
.percent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
}    
.width10 {
    width: 10%;
}
.width15 {
    width: 15%;
}
.width25 {
    width: 25%;
}
.width30 {
    width: 30%;
}
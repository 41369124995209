.text{
    background-color: white;
    color: black;
    padding: 10px;
    max-width: 800px;
}
.text p{
    margin: 10px 0px;
    text-indent: 30px;
}

.assetsTableContainer {
    border-top: 2px solid #025498;
    width: 65%;//calc(200% / 3 + 100px);
    padding: 25px 10px;
    box-sizing: border-box;
    margin-left: 7px; 
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-left: 0px; 
      margin-top: 10px;
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 540px) {
        overflow-x: scroll;
    }
}

.assetTable {
    border-spacing: 0px;
    background-color: transparent !important;
}
.title {
    font-size: 20px;
    font-weight: 600;
}
.tableBody {
    margin-top: 8px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    @media only screen and (max-width: 1280px) {
        font-size: 12px;
    }
    @media only screen and (max-width: 768px) {
        font-size: 13px;
    }
    @media only screen and (max-width: 540px) {
        font-size: 11px;
    }
}
.tableRow {
    border-bottom: 1px solid #35495e;
    height: 40px;
}
.header_tr {
    height: 40px;
}
.th {
    text-align: start;
    padding-left: 2px;
}
.action_td {
    cursor: pointer;
}
.logo {
    width: 20px;
    margin-right: 8px;
    border-radius: 50%;
    @media only screen and (max-width: 1024px) {
        width: 20px;
        margin-right: 4px;
    }
}    
.usdAmountCell {
    // display: flex;
    // flex-direction: column;
}
.nameCell {
    display: flex;
    align-items: center;
    margin-top: 8px;
}
.popup {
    position: absolute;
    width: 480px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #00000090;
    padding: 20px;
    border-radius: 5px;
    color: white;
    .desc {
      margin: 12px 0;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }
}
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');
* {
  padding-top: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  color: white;
  font-family: 'Montserrat', sans-serif !important;
}
ul {
  padding: 0;
}

.main {
  display: flex;
  flex-direction: column;
  background: url('./assets/bg/main-dark.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow-x: auto;
  min-height: 100vh;
  
  .bg-main {
    z-index: 2;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .bg-l {
    background: url('./assets/bg/main-light.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .backgroundVideo {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
  &.pay {
    background: url('./assets/bg/bridge.jpeg');
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
  }
  &.dashboard {
    background: #1e2328;
  }
  &.domainsresult {
    background-attachment: fixed;
    background-image: url('./assets/bg/searchEngine.jpg');
    background-repeat: repeat-x;
    background-size: cover;
    animation: 1350s domainResultBackImgAnimation infinite linear;
  }
  &.domainsdashboard {
    background-attachment: fixed;
    background-image: url('./assets/bg/domain.jpg');
    background-repeat: repeat-x;
    background-size: cover;
    animation: 1350s domainBackImgAnimation infinite linear;
  }
  &.Affiliates,
  &.SendEmail {
    background: url('./assets/bg/downline-sendemail.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
  &.dreaming {
    .dreamingImg {
      width: 100%;
      position: absolute;
      top: -50%;
      z-index: 0;
    }
  }
  &.search {
    background-attachment: fixed;
    background-image: url('./assets/bg/searchEngine.jpg');
    background-repeat: repeat-x;
    background-size: cover;
    animation: 1350s searchEngineBackImgAnimation infinite linear;
  }
  &.portfolio {
    .portfolioImg {
      width: 100%;
      height: 100vh;
      position: absolute;
      // background-image: url('./assets/bg/portfolio.png');
      // background-size: cover;
      // background-position: top;
    }
    .portfolioImg-l {
      background: url('./assets/bg/main-light.jpg');
      // background: #e8eff7;
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      z-index: 2;
    }
  }
  &.marketspace {
    background: url('./assets/bg/marketspace.png');
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  &.enter {
    background-attachment: fixed;
    background-image: url('./assets/bg/domain.jpg');
    background-repeat: repeat-x;
    background-size: cover;
    animation: 1350s enterBgAnimation infinite linear;
  }
}

/* For Chrome */
/* width */
::-webkit-scrollbar {
  @media all and (min-width: 990px) {
    width: 0px;
    height: 2px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #35495e46;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
  height: 48px;
  width: 48px;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #025498a2;
}

/* Animations */
@keyframes domainResultBackImgAnimation {
  100% {
    background-position: -5000px;
  }
}
@keyframes domainBackImgAnimation {
  100% {
    background-position: -5000px;
  }
}
@keyframes enterBgAnimation {
  100% {
    background-position: 5000px;
  }
}
@keyframes searchEngineBackImgAnimation {
  100% {
    background-position: 5000px;
  }
}

/* Floating Hashtags */
.hashtags {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  top: 0;
  left: 0;
}
.hashtag {
  position: absolute;
  bottom: -10px;
  // width: 42px;
  // height: 42px;  
  // background: url('./assets/icons/hash-small.svg');
  background: url('./assets/icons/hashtag-c.png');
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  animation: rise 10s infinite ease-in;
}
.hashtag:nth-child(1) {
  width: 24px;
  height: 24px;
  left: 10%;
  animation-duration: 34s;
}
.hashtag:nth-child(2) {
  width: 20px;
  left: 20%;
  animation-duration: 30s;
  animation-delay: 12s;
}
.hashtag:nth-child(3) {
  width: 50px;
  height: 50px;
  left: 35%;
  animation-duration: 60s;
  animation-delay: 2s;
}
.hashtag:nth-child(4) {
  width: 30px;
  height: 30px;
  left: 50%;
  animation-duration: 40s;
  animation-delay: 0s;
}
.hashtag:nth-child(5) {
  width: 35px;
  height: 35px;
  left: 55%;
  animation-duration: 45s;
  animation-delay: 9s;
}
.hashtag:nth-child(6) {
  width: 45px;
  height: 45px;
  left: 65%;
  animation-duration: 55s;
  animation-delay: 7s;
}
.hashtag:nth-child(7) {
  width: 40px;
  height: 40px;
  left: 70%;
  animation-duration: 50s;
  animation-delay: 2s;
}
.hashtag:nth-child(8) {
  width: 25px;
  height: 25px;
  left: 80%;
  animation-duration: 35s;
  animation-delay: 3s;
}

@keyframes rise {
  0% {
    bottom: -100px;
    transform: translateX(0);
  }
  50% {
    transform: translate(200px);
  }
  100% {
    bottom: 1280px;
    transform: translateX(-200px);
  }
}


/* Transaction Routes */
/*Home  fade Transition with transform */
.homeFadeTransition-enter {
  opacity: 0;
  transform: scale(0.7);
}
.homeFadeTransition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 2000ms, transform 2000ms;
}
.homeFadeTransition-exit {
  opacity: 1;
}
.homeFadeTransition-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 2000ms, transform 2000ms;
}


/* theBRIDGE Form Fade Transition */
.bridgeFadeTransition-enter {
  opacity: 0;
}
.bridgeFadeTransition-enter.bridgeFadeTransition-enter-active {
  opacity: 1;
  transition: opacity 2500ms ease-in;
}
/* bridge Table fade Trasition with transform */
.bridgeTableFadeTransition-enter {
  opacity: 0;
  transform: scale(0.7);
}
.bridgeTableFadeTransition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 3000ms, transform 3000ms;
}
.bridgeTableFadeTransition-exit {
  opacity: 1;
}
.bridgeTableFadeTransition-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 3000ms, transform 3000ms;
}


/* searchEngine Category Transition */
.categoryFadeTransition-enter {
  opacity: 0;
}
.categoryFadeTransition-enter.categoryFadeTransition-enter-active {
  opacity: 1;
  transition: opacity 2500ms ease-in;
}


/* SearchEngine fade Transition with transform */
.searchEngineFadeTransition-enter {
  opacity: 0;
  transform: scale(0.8);
}
.searchEngineFadeTransition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 4500ms, transform 4500ms;
}
.searchEngineFadeTransition-exit {
  opacity: 1;
}
.searchEngineFadeTransition-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 4500ms, transform 4500ms;
}


/* domain Dashboard Fade Transition */
.domainDashboardFadeTransition-enter {
  opacity: 0;
}
.domainDashboardFadeTransition-enter.domainDashboardFadeTransition-enter-active {
  opacity: 1;
  transition: opacity 3000ms ease-in-out;
}


/* Slide Transition */
.slide-enter,
.slide-exit {
  transition: transform 1000ms ease-out;
}
.slide-enter {
  transform: translateX(100%);
}
.slide-enter.slide-enter-active {
  transform: translateX(0%);
}
.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}
.slide-exit-active {
  transform: translateX(-100%);
}


/* SearchEngine Category Animation */
.searcEngineCategoryTransRTL-enter,
.searcEngineCategoryTransRTL-exit {
  transition: transform 2500ms ease-in-out;
}
.searcEngineCategoryTransRTL-enter {
  transform: translateX(120%);
  opacity: 0;
}
.searcEngineCategoryTransRTL-enter.searcEngineCategoryTransRTL-enter-active {
  transform: translateX(0%);
  opacity: 1;
}
.searcEngineCategoryTransRTL-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
  opacity: 1;
}
.searcEngineCategoryTransRTL-exit-active {
  transform: translateX(-120%);
  opacity: 0;
}


.react-horizontal-scrolling-menu--wrapper {
  // flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.react-horizontal-scrolling-menu--scroll-container {
  display: flex;
  height: max-content;
  position: relative;
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
}

.react-horizontal-scrolling-menu--item:first-child {
  margin-left: 50px;
}
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

@keyframes fadeBody {
  from {opacity: 0.5;}
  to {opacity: 1;}
}
  .wraper {
    width: 100%;
  }
  .form {
    padding: 30px 25px 40px 25px;
    /* background: rgba(0, 0, 0, 0.8); */
    border-top: 1px solid #025498;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .formCol1 {
    flex-basis: calc(20% - 25px);
    padding-right: 25px;
  }
  .formCol3 {
    flex-basis: calc(30% - 45px);
    padding-left: 45px;
  }
  .checkboxAftertit {
    font-size: 12px;
  }
  .formTit {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .checkGroup {
    margin-bottom: 60px;
  }
  .threecol {
    width: 250px;
    display: inline-flex;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .threecol.long {
    width: 520px;
  }
  .mark {
    margin-top: 30px;
  }
  .threecollast {
    display: block;
  }
  .twocol {
    display: flex;
    width: calc(100% / 2);
    margin-top: 40px;
  }
  .onecol {
    display: flex;
    width: 100%;
  }
  .formRow {
    margin-bottom: 15px;
  }
  .border {
    border-bottom: 1px solid #354955;
    margin: 45px 0;
    width: calc(100% - 10px);
  }
  .modtit {
    font-size: 14px;
    font-weight: 600;
  }
  .formBtn {
    border: none;
    outline: none;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    background: -webkit-gradient(linear, left top, left bottom, from(#873f99), to(#311438));
    background: linear-gradient(#873f99, #311438);
    color: #000;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-top: 20px;
    border-radius: 5px;
  }
  .formBtn:hover {
    background: linear-gradient(#ffc600, #f48f73);
    cursor: pointer;
  }
  .formBtn:active {
    background: linear-gradient(#f48f33, #fbb212);
  }
  .formBtn:disabled {
    background: #a2a2a2;
    cursor: default;
  }
  .switchlabel {
    margin-bottom: 4px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }
  .switch {
    margin-top: 10px;
  }
  .switch svg {
    display: none;
  }
  .input label {
    margin-bottom: 4px;
    font-size: 12px;
    display: flex;
  }
  .input {
    width: 100%;
    padding-right: 10px;
  }
  .readOnly {
    opacity: 0.5;
  }
  .readOnly input {
    cursor: default;
  }
  .input.error {
    border-color: red;
    outline: none;
    color: red;
  }
  .imgForFile {
    position: absolute;
    margin-top: 10px;
    max-width: 100px;
    height: 100px;
  }
  .code {
    text-align: center;
    font-size: 36px;
  }
  .bio_col {
    display: flex;
    flex-direction: column;
  }
  .bio_col textarea {
    width: 510px;
    height: 200px;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid white;
    color: white;
  }
  .bio_col label {
    font-size: 12px;
    margin-bottom: 4px;
  }
  .soc {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#ffc600, #f59131);
    color: black;
    text-decoration: none;
    margin-top: 4px;
    margin-right: 4px;
    flex-shrink: 0;
  }
  .soc_input input {
    font-size: 12px !important;
    padding: 0 5px !important;
  }
  .all_input {
    margin-left: 5px;
  }
  
  @media only screen and (max-width: 1200px) {
    .formCol1 {
      flex-basis: calc(20% - 15px);
      padding-right: 15px;
    }
    .formCol2 {
      flex-basis: 50%;
    }
    .formCol3 {
      flex-basis: calc(30% - 15px);
      padding-left: 15px;
    }
    .formTit {
      font-size: 14px;
    }
    .modtit {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 900px) {
    .imgForFile {
      position: relative;
    }
  }
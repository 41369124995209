.web3_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  width: 100%;
  padding: 0 15px 15px 15px;
}
.grid_container {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 33% 33% 33%;
  // grid-template-columns: auto auto auto;
  background: transparent;
  width: 100%;
  grid-gap: 10px;
}
.grid_item {
  // background: #00000090;
  border-top: 1px solid #025498;
  padding: 10px;
  height: 350px;
}
.input_item {
  position: relative;
}
.target_url {
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}
.input_box {
  padding-right: 20px;
}
.launch_icon {
  position: absolute;
  right: 9px;
  top: 9px;
  cursor: pointer;
  width: 25px;
}
.web_input {
  position: relative;
}
.web_item {
  padding: 3px;
}
.web_frame {
  width: 100%;
  height: 260px;
  margin: 5px 0 0 0;
  border: none;
  overflow: hidden;
  border-radius: 2px;
}
.bottom_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
.b_item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.b_val {
  margin: 0 8px;
}




/// Pagination
.p_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  list-style: none;
  color: white;
}
.p_container_l {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  list-style: none;
  color: #000;
}
.p_page {
  border-radius: 4px;
  width: 25px;
  margin: 0 4px;
  text-align: center;
  cursor: pointer;
}
.p_active {
  background: transparent;
  border: 2px solid white;
  font-weight: bold;
}
.p_active_l {
  background: transparent;
  border: 2px solid black;
  font-weight: bold;
}
.p_pre {
  font-weight: bold;
  cursor: pointer;
}
.p_disable {
  color: gray;
}
.count_container {
  position: relative;
  width: 100%;
}
.save_btn {
  border: none;
  text-align: center;
  width: 150px;
  height: 40px;
  padding: 5px 0px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
}


@media (max-width: 640px) { 
  .grid_container {
    grid-template-columns: auto !important;
  }
}
@media (max-width: 768px) { 
}
@media (max-width: 1024px) {  
  .grid_container {
    grid-template-columns: auto auto;
  }
}
@media (max-width: 1280px) { 
}
@media (max-width: 1536px) { 
}
.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .formTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    font-family: "Montserrat";
    img {
      width: 40px;
      margin-top: -10px;
      margin-left: -8px;
    }
  }
}
.starLevel {
  display: flex;
}
.dream-item {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 8px 0px;
}
.dream-space-item {
    display: flex;
    flex-direction: column;
    width: 102%;
    padding: 0 10px;
    overflow-y: scroll;
}

.dream-row-line1 {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dream-row-line2 {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}
.live {
    background-color: red;
    border-radius: 7px;
    padding: 0px 5px;
    font-size: 12px;
    font-weight: bold;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.seener {
    font-weight: bold;
    margin-left: 10px;
}
.follow {
    margin-left: 5px;
    margin-right: 10px;
    font-size: 12px;
}
.comment {
    margin-left: 5px;
    font-size: 12px;
}
.dream-title {
    font-size: 18px;
    font-weight: 600;
}

.dream-img {
    width: 130px;
    height: 90px;
    border-radius: 5px;
}
.dream-table {
    background-color: transparent !important;
    margin-top: 10px;
}

.msg-time {
    color: gray;
}
.dream-td {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 10px 0;
}

.search-dream-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 10px 20px;
    overflow-x: scroll;
}
.search-dream-item {
    display: flex;
    flex-direction: column;    
}
.stream-title {
    padding: 15px;
}
.stream-bottom-title {
    width: 100%;
    text-align: center;
    padding: 20px;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
}
.stream-row-line1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.stream-row-line2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}
.stream-img {
    width: 200px;
    height: 120px;
    border-radius: 5px;
}
.test {
    color: linear-gradient(#ce39f3, #80069e);
}
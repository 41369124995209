.modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  position: fixed;
  overflow: hidden;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  color: black;
  transition: opacity 0.3s linear;

  &-content {
    width: 50vw;
    padding: 25px 30px;
    background: white;
    border-radius: 10px;
    &__header {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 24px;
        font-weight: bold;
      }
      .modal-close {
        margin-right: -15px;
        cursor: pointer;
      }
    }
  }
}
.modal-inp {
  display: flex;
  flex-direction: column;
  margin: 30px 0 40px 0;
  & input {
    height: 60px;
    box-shadow: none;
    outline: none;
    border: 1px solid #969999;
    padding: 0 20px;
    font-size: 20px;
    border-radius: 5px;
  }
  & label {
    font-size: 14px;
    margin-bottom: 7px;
  }
  &.error {
    color: red;
    input {
      border-color: red;
    }
  }
}

.modal-content__btns {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 35px;
}

.modal-content__btns .btn {
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  display: flex;
  cursor: pointer;
  justify-content: center;
  width: 60px;
  align-items: center;
}
// .modal-content__btns .btn:first-child {
//   color: #fdbe08;
//   border: 1px solid #fdbe08;
// }
// .modal-content__btns .btn:first-child:hover {
//   //color: #fdae08;
//   border-color: #fdae08;
// }
.modal-content__btns .btn:last-child {
  margin-left: 25px;
  color: black;
  height: 37px;
  background: linear-gradient(#873f99, #311438);
  border: none;
}
.modal-content__btns .btn:last-child:hover {
  background: linear-gradient(#873f99, #311438);
}
.modal-content__btns .btn:last-child:active {
  background: linear-gradient(#873f99, #311438);
}

.modal.animated-enter {
  opacity: 0.01;
}
.modal.animated-enter-active {
  opacity: 1;
}

.modal.animated-exit {
  opacity: 0;
}
.modal.animated-exit-active {
  opacity: 0;
}

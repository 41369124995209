.modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  color: black;
  transition: opacity 0.3s linear;

  & .content {
    width: 800px;
    padding: 25px 30px;
    background: rgba(0, 0, 0, 0);
    max-height: 90vh;
    overflow: auto;
    border-radius: 10px;

    & .email {
      width: 100%;

      :global(.wrapper) {
        background: url("../../../assets/bg/hashtag_space_bg.png") top center;
        background-size: cover;

        :global(.container) {
          width: 540px;
          margin: 0 auto;
        }

        :global(.content) {
          padding: 80px 0 200px;

          :global(.logo) {
            margin-bottom: 60px;
          }

          :global(.title) {
            margin-bottom: 50px;
            font-family: Arial;
            font-weight: bold;
            font-size: 30px;
            line-height: 100%;
            color: #ffffff;
          }

          :global(.text) {
            background: rgba(0, 0, 0, 0);
            padding: 0;

            h1 {
              font-family: Arial;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 125%;
              color: #ffffff;
              margin: 0 0 10px;
            }
            p {
              font-family: Arial;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 125%;
              color: #ffffff;
              text-indent: 0;
              margin: 0 0 30px;
            }
            hr {
              opacity: 0.1;
              margin: 0 0 30px;
            }
          }
          :global(.actions) {
            margin: 0 -10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;

            :global(.spacer) {
              flex: 1 0 auto;
            }

            button {
              margin: 0 10px;
              min-width: 250px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 16px;
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;
              text-align: center;
              text-transform: uppercase;
              color: #ffffff;
              border: none;
              background: #55acee;
              border-radius: 4px;
              outline: none;
              cursor: pointer;
            }
          }
        }

        :global(.footer) {
          padding: 30px 0 60px;
          background: #001c3d;

          :global(.container) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          :global(.left) {
            width: 300px;
            font-family: Arial;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 100%;
            color: #ffffff;

            :global(.copyright) {
              margin-bottom: 8px;
            }

            a {
              color: #fff;
              text-decoration: underline;
            }
          }

          :global(.right) {
            width: calc(100% - 300px);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-wrap: wrap;

            a {
              margin: 6px;
            }
          }
        }
      }
    }
  }
  & .actions {
    padding: 20px 32px 30px;
    margin: 0 -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    :global(.spacer) {
      flex: 1 0 auto;
    }

    button {
      margin: 0 10px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #000000;
      border: none;
      background: linear-gradient(180deg, #ffc601 0%, #f59033 100%);
      outline: none;
      cursor: pointer;

      &:global(.transparent) {
        padding: 0;
        color: #26a4ff;
        background: rgba(0, 0, 0, 0);
      }
    }
  }
}

.main-contentss {
  display: flex;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  justify-content: center;
  // margin-bottom: 140px;  <FooterBottom />
  margin-bottom: 100px;
  min-height: calc(100vh - 215px);

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;    
    justify-content: flex-start;
  }
  @media only screen and (max-width: 640px) {
    padding: 0 5px;
  }
}
.main-contentss.cn__maincontent_startpage {
  align-items: center;
  .forgotPwdLinkContainer,
  .headerLoginLinksContainer {
    width: 100%;
    text-align: end;
    padding-right: 5px;
    box-sizing: border-box;
    font-weight: 700;

    .forgotP {
      color: white;
      &:hover {
        color: #fdbe08;
        text-decoration: none;
      }
    }
    .forgotP-d {
      color: #000;
      &:hover {
        color: #fdbe08;
        text-decoration: none;
      }
    }
  }
  .headerLoginLinksContainer {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .resetPwdConfirmContainer {
    width: 100%;
    word-break: break-all;
    line-height: 24px;
    margin-top: 24px;
    padding: 0px 8px;
    box-sizing: border-box;
    text-align: center;
    .resetPwdErrorTxt {
      color: red;
    }
    .resetPwdConfirmTxt {
      color: #e68105;
    }
  }
}


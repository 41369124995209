.menu {
  width: 235px;
  // background: #000000c9;//#000000e3;
  // border: 1px solid #ffffff54;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-self: flex-start;  
  position: relative;
  margin-right: 15px;
  .dream-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.dropdownLeft {
    .dropdown {
      &__list,
      &__admin {
        left: auto;
        right: 100%;
      }
    }
  }

  a {
    display: flex;
    height: 60px;
    color: white;
    font-size: 14px;
    text-decoration: none;
    align-items: center;
    font-weight: 700;
    padding-left: 20px;
    cursor: pointer;

    .menu_text {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      height: 60px;
      width: 130px;
      // width: 58%;
      cursor: pointer;

      &_secondary {
        font-size: 10px;
        margin-top: 4px;
        color: #fff; //#caced2;
      }

      .dreaming {
        font-size: 52px;
        line-height: 15px;
      }
    }
    .menu__alarm {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #ee092f;
      display: inline-block;
      margin-left: 25px;
      font-size: 13px;
      text-align: center;
    }
  }
  a:hover {
    background: linear-gradient(#873f99, #311438);
  }
  a.active {
    background: linear-gradient(#873f99, #311438);
    color: #fff;
    .menu_text_secondary {
      color: #fff;
    }
  }
  a.active:hover {
    cursor: default;
  }
  .dropdown {
    position: relative;

    &:hover {
      .dropdown {
        &__title {
          background: linear-gradient(#873f99, #311438);
          color: #fff;
          .menu_text_secondary {
            color: #fff; //#caced2;
          }
        }

        &__list,
        &__admin {
          display: block;
          z-index: 100;
        }
      }
    }
    &__title {
      display: flex;
      height: 60px;
      color: white;
      font-size: 14px;
      text-decoration: none;
      align-items: center;
      font-weight: 700;
      padding-left: 20px;
      cursor: pointer;
      text-transform: uppercase;
      position: relative;

      svg {
        margin-right: 25px;
      }
      .menu_text {
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: pointer;

        &_secondary {
          font-size: 10px;
          margin-top: 4px;
          color: #fff; //#caced2;
        }
      }
    }
    &__chevron {
      position: absolute;
      top: calc(50% - 9px);
      right: 0;
    }
    &__list,
    &__admin {
      display: none;
      position: absolute;
      left: 100%;
      top: 0;
      width: 235px;
      background: linear-gradient(#873f99, #311438);

      a {
        &:hover {
          background: linear-gradient(#873f99, #311438);
          color: #fff;
          .menu_text_secondary {
            color: #fff; //#caced2;
          }
        }
        svg {
          margin-right: 25px;
        }
      }
    }
    &__admin {
      bottom: 0;
      top: unset;
    }
  }
  .externalIcon {
    margin-right: 20px;
    cursor: pointer;
  }
  .menu_header {
    padding: 7px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ffffff90;
    width: 100%;
  }
  .headerIcon {
    cursor: pointer;
  }
}
.menu-l {
  width: 235px;
  // background: #ffffffc9;//#ffffffe3;
  // border: 1px solid #26a5e0ab;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-self: flex-start;
  position: relative;
  margin-right: 15px;
  &.dropdownLeft {
    .dropdown {
      &__list,
      &__admin {
        left: auto;
        right: 100%;
      }
    }
  }

  a {
    display: flex;
    height: 60px;
    color: #000;
    font-size: 14px;
    text-decoration: none;
    align-items: center;
    font-weight: 700;
    padding-left: 20px;
    cursor: pointer;

    .menu_text {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      height: 60px;
      width: 130px;
      // width: 58%;
      cursor: pointer;

      &_secondary {
        font-size: 10px;
        margin-top: 4px;
        color: #000; //gray;
      }

      .dreaming {
        font-size: 52px;
        line-height: 15px;
      }
    }
    .menu__alarm {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #ee092f;
      display: inline-block;
      margin-left: 25px;
      font-size: 13px;
      text-align: center;
    }
  }
  a:hover {
    background: linear-gradient(#b7eaf6, #777fc4);
  }
  a.active {
    background: linear-gradient(#b7eaf6, #777fc4);
    color: #000;
    .menu_text_secondary {
      color: #000;
    }
  }
  a.active:hover {
    cursor: default;
  }

  .dropdown {
    position: relative;

    &:hover {
      .dropdown {
        &__title {
          background: linear-gradient(#b7eaf6, #777fc4);
          color: #000;
          .menu_text_secondary {
            color: #000; //gray;
          }
        }

        &__list,
        &__admin {
          display: block;
          z-index: 100;
        }
      }
    }
    &__title {
      display: flex;
      height: 60px;
      color: #000;
      font-size: 14px;
      text-decoration: none;
      align-items: center;
      font-weight: 700;
      padding-left: 20px;
      cursor: pointer;
      text-transform: uppercase;
      position: relative;

      svg {
        margin-right: 25px;
      }
      .menu_text {
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: pointer;

        &_secondary {
          font-size: 10px;
          margin-top: 4px;
          color: #000; //gray;
        }
      }
    }
    &__chevron {
      position: absolute;
      top: calc(50% - 9px);
      right: 0;
    }
    &__list,
    &__admin {
      display: none;
      position: absolute;
      left: 100%;
      top: 0;
      width: 235px;
      background: linear-gradient(#b7eaf6, #777fc4);

      a {
        &:hover {
          background: linear-gradient(#b7eaf6, #777fc4);
          color: #000;
          .menu_text_secondary {
            color: #000; //gray;
          }
        }
        svg {
          margin-right: 25px;
        }
      }
    }
    &__admin {
      bottom: 0;
      top: unset;
    }
  }
  .externalIcon {
    margin-right: 20px;
    cursor: pointer;
  }
  .menu_header {
    padding: 7px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 195, 255, 0.795);
    width: 100%;
  }
  .headerIcon {
    cursor: pointer;
  }
}
.menubg {
  background: #000000a3;//#000000e3;
}
.menubg-l {
  background: #ffffffa3;//#000000e3;
}
.m-top {
  margin-top: 7px;
}

.dream-extraIcon {
  display: flex;
  align-items: center;
}
.tooltip-menu {
  width: 40vw !important;
  .multi-line {
    text-align: left !important;
  }
}
.tooltip-open {
  width: fit-content !important;
  .multi-line {
    text-align: left !important;
  }
}
.icon-div {
  display: flex;
  width: 100%;
  margin-right: 5px;
  justify-content: space-evenly;
}
.icon-span {
  display: flex;
  align-items: center;
  margin: 0 5px;
  cursor: pointer;
}
.icon-tab {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.icon-span1 {
  display: flex;
  align-items: center;
  margin: 0 -3px;
  cursor: pointer;
}
.control_num {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.menuIconNotification {
  cursor: pointer;
  margin: 2px 0 0 15px;  
  .withdrawReqCount {
    border-radius: 50%;
    background-color: rgb(250, 60, 59);
    font-size: 11px;
    position: absolute;
    right: 10px;
    top: 2px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.dreamIcon {
  height: 60px;
  align-items: center;
  display: flex;
}
.useravatar {
  cursor: pointer;
}
.planet_icon {
  width: 30px;
  cursor: pointer;
}
.sub-menu-title {
  font-size: 12px;
  font-weight: 600;
  padding: 7px 10px;
}

@media (max-width: 640px) { 
}
@media (max-width: 768px) { 
  .menu, .menu-l {
    width: 100%;
    margin-bottom: 10px;
    z-index: 2;
  }
  .menu_text {
    width: 90% !important;
  }
  .dropdown__admin {
    left: 0 !important;
    bottom: 60px !important;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1536px) { 
}
@media (max-width: 1660px) { 
}
.sparkAnimationContainer {
	.glow_text {
		animation: glow 1s ease-in-out infinite alternate;
	}
	.glow_text_l {
		animation: glowL 1s ease-in-out infinite alternate;
	}
	.glow_text_big {
		position: absolute;
		font-size: 52px;
		top: -41px;
		animation: glow_big 1s ease-in-out infinite alternate;
	}
	.glow_text_big_l {
		position: absolute;
		font-size: 52px;
		top: -41px;
		animation: glow_big_l 1s ease-in-out infinite alternate;
	}

	@keyframes glow {
		from {
			text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #ad5503, 0 0 4px #ad5503, 0 0 5px #ad5503, 0 0 6px #ad5503, 0 0 7px #ad5503;
		}

		to {
			text-shadow: 0 1px 5px #fff, 0 0 5px #e0b105, 0 0 7px #e0b105, 0 0 8px #e0b105, 0 0 10px #e0b105, 0 0 10px #e0b105, 0 0 11px #e0b105;
		}
	}
	@keyframes glowL {
		from {
			text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #18191a, 0 0 4px #18191a, 0 0 5px #18191a, 0 0 6px #18191a, 0 0 7px #18191a;
		}

		to {
			text-shadow: 0 1px 5px #fff, 0 0 5px #777fc4, 0 0 7px #777fc4, 0 0 8px #777fc4, 0 0 10px #777fc4, 0 0 10px #777fc4, 0 0 11px #777fc4;
		}
	}

	@keyframes glow_big {
		from {
			text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #ad5503, 0 0 4px #ad5503, 0 0 5px #ad5503, 0 0 6px #ad5503, 0 0 7px #ad5503;
		}

		to {
			text-shadow: 0 1px 5px #fff, 0 0 5px #e0b105, 0 0 7px #e0b105, 0 0 8px #e0b105, 0 0 10px #e0b105, 0 0 10px #e0b105, 0 0 11px #e0b105;
		}
	}
	@keyframes glow_big_l {
		from {
			text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #18191a, 0 0 4px #18191a, 0 0 5px #18191a, 0 0 6px #18191a, 0 0 7px #18191a;
		}

		to {
			text-shadow: 0 1px 5px #fff, 0 0 5px #777fc4, 0 0 7px #777fc4, 0 0 8px #777fc4, 0 0 10px #777fc4, 0 0 10px #777fc4, 0 0 11px #777fc4;
		}
	}
}
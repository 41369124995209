.keyField {
    margin-top: 15px;
    border: 1px solid;
    border-radius: 5px;
    background: rgba(0,0,0,0.5);
    padding: 15px;
    height: 120px;
    margin-bottom: 30px;
    overflow: auto;
    position: relative;
}
.keyFieldTit {
    font-size: 14px;
    margin-bottom: 15px;
}
.keyOverWord {
    display: flex;
    flex-wrap: wrap;
    margin-top: 100px;
}
.error{
    border:1px solid red;
}
.tokenContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 11px;  
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
}
.sContainer {
  display: flex;
}
.sub_container {
  display: flex;
  flex-direction: column;
  margin: 0 3px;
}
.item_top {  
  display: flex;
  align-items: center;
  justify-content: center;
}
.nowrap {
  white-space: nowrap;
  min-width: 65px;
}
.item_icon {
  width: 27px;
  height: 27px;
}
.item_bottom {
  display: flex;
  // justify-content: center;
  align-items: center;
  margin-top: -10px;
  margin-left: 31px;
}
.txt_bottom {
  font-size: 12px;
  font-weight: 700;
}


@media (max-width: 640px) { 
}
@media (max-width: 768px) { 
  .tokenContainer {
    flex-direction: column;
    width: 100%;
  } 
  .sContainer {
    justify-content: space-between;
    width: 100%;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 1280px) { 
}
@media (max-width: 1536px) {   
}
@media (max-width: 1660px) { 
  // .tokenContainer {
  //   flex-direction: column;
  //   width: 100%;
  // } 
}
.header-login-links {
    font-size: 20px;
    color: white;
}
.header-login-links a {
    color: #fdbe08;
    padding-left: 7px;
    text-decoration: underline;
    cursor: pointer;
}
.header-login-links a:hover {
    text-decoration: none;
}
.walletWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  padding: 8px 4px;
  box-sizing: border-box;
  &:hover {
    background-color: #0093fc2c;
    cursor: pointer;
  }
}
.input input {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  border: 1px solid gray;
  border-radius: 5px;
  height: 40px;
  font-size: 14px;
  padding: 0 10px;
  color: white;
  width: 100%;
  outline: none;
}
.input label {
  margin-bottom: 4px;
  font-size: 12px;
  display: flex;
}
.input {
  width: 100%;
}
.readOnly {
  opacity: 0.5;
}
.readOnly input {
  cursor: default;
}
.input.error {
  border-color: red;
  outline: none;
  color: red;
}
.input.error input {
  border-color: red;
  outline: none;
}

.input.isFocusStyle input:focus {
  box-shadow: 0 0 8px 1px #598ff3;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #5d90dc;
}

.input.hashtagselect {
  position: absolute;
  width: 60px;
  left: 5px;
  top: 8px;
  padding-right: 0;
}
.input.hashtagselect label {
  margin-bottom: 0;
}
.input.hashtagselect .select {
  border-color: transparent;
  /*padding-right: 30px;*/
}

.input.hashtagselect select {
  color: black;
  background: transparent;
  font-size: 20px;
  font-weight: bold;
  padding-left: 30px;
}
.input.hashtagselect .selectArrow {
  border-color: black transparent transparent transparent;
  display: none;
}

.select {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0px;
  border: 1px solid;
  border-radius: 5px;
}

.select select {
  width: 100%;
  display: flex;
  height: 38px;
  padding: 10px 10px;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  outline: 0;
  background: rgba(0, 0, 0, 0.5);
  /* color: white; */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
}

.select select::-ms-expand {
  display: none;
}

.select select:hover,
.select select:focus {
}

.select select:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.select select:hover ~ .select__arrow,
.select select:focus ~ .select__arrow {
  border-top-color: white;
}

.select select:disabled ~ .select__arrow {
  border-top-color: #ccc;
}
.selectArrow {
  position: absolute;
  top: 18px;
  right: 10px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-width: 6px 4px 0 4px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}
.selectArrow_l {
  position: absolute;
  top: 18px;
  right: 10px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-width: 6px 4px 0 4px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.input.categoryselect {
  margin-bottom: 30px;
  margin-top: 30px;
  padding-right: 0;
}
.input.categoryselect label {
  display: flex;
  align-items: center;
}
.input.categoryselect .select {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}
.input.categoryselect .select select {
  width: 100%;
  display: flex;
  height: 40px;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 0;
  outline: 0;
  background: rgba(0, 0, 0, 0.5);
  appearance: none;
  color: white;
  font-size: 14px;
}

.input textarea {
  resize: none;
  width: 100%;
  height: 80px;
  padding: 12px;
  background-color: transparent;
}

@media only screen and (max-width: 1200px) {
  .input.categoryselect .select {
    width: 60%;
  }
}

.columContainer {
  position: relative;
  display: flex;
  flex-direction: column;  
}
.results {
  border-bottom: 1px solid #20303f;
  padding: 5px 15px;
}
.form {
  padding: 18px 24px;
  /* background: rgba(0, 0, 0, 0.7); */
}
.formContainer {
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  height: 53px;
  width: 100%;
  position: relative;
}
.hashTag {
  width: 90px;
  height: 68px;
}
.searchEngineInp {
  height: 53px;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #333333;
  width: 100%;
  outline: navajowhite;
  background-color: #333333;
  color: #ffffff;
  border-radius: 4px;
}
.formContainer .searchIcon {
  color: #a0a0a0;
  position: absolute;
  right: 16px;
}
.form_input_err {
  margin-right: 5%;
  color: red;
  text-align: right;
  font-size: 14px;
}
.list_item {
  height: 78px;
  display: flex;
  border-bottom: 1px solid #20303f;
  align-items: center;
  border-radius: 5px;
}
.itemNum {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 16px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #636363;
}
.itemLinks {
  border: none;
  outline: none;
  color: white;
  word-break: break-all;
  text-align: left;
  background: transparent;
}
.itemLinks a {
  color: white;
}

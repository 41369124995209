.wraper {
  width: 100%;
  overflow: hidden;
  z-index: 0;
}
.form {
  padding: 30px 25px 40px 25px;
  background: rgba(0, 0, 0, 0.8);
  border-top: 2px solid #025498;
  flex-direction: row;
  flex-wrap: wrap;
  display: block;
  height: 650px;
}
.form div:global.cm-s-material.CodeMirror {
  height: 500px;
}
.formCol1 {
  flex-basis: calc(20% - 25px);
  padding-right: 25px;
}
.formCol3 {
  flex-basis: calc(30% - 45px);
  padding-left: 45px;
}
.formRow {
  margin-bottom: 15px;
}
.threecol {
  width: 250px;
  display: inline-flex;
  margin-bottom: 20px;
  margin-right: 20px;
}
.threecol.long {
  width: 520px;
}
.label {
  margin-bottom: 4px;
  font-size: 12px;
  display: flex;
}
.label.error {
  color: red;
}
.textarea {
  width: 100%;
  height: 400px;
  background: transparent;
  border-color: white;
  color: white;
}
.formTit {
  font-size: 18px;
  margin-bottom: 30px;
}
.formBtn {
  border: none;
  outline: none;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  background: -webkit-gradient(linear, left top, left bottom, from(#873f99), to(#311438));
  background: linear-gradient(#873f99, #311438);
  color: #000;
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
}
.formBtn:disabled {
  background: #a2a2a2;
  cursor: default;
}


@media (max-width: 640px) { 
}
@media (max-width: 768px) { 
}
@media (max-width: 1024px) {
  .form {
    padding: 15px !important;
    height: 620px !important;
  }
}
@media (max-width: 1280px) {
}
@media (max-width: 1536px) { 
}
@media (max-width: 1660px) { 
}
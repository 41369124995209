.top-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    width: 100%;
}
.top-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
.marginLeft {
    margin-left: 10px;
}
.bottom-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.item-rect {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: #3e3d3dae;
    width: 100%;
    padding: 10px 15px;
    margin: 10px;
}
.status-lay {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 640px) { 
    .bottom-item {
        flex-direction: column;
    }
}
@media (max-width: 768px) { 
}
@media (max-width: 1024px) {
}
@media (max-width: 1280px) { 
}
@media (max-width: 1536px) { 
}
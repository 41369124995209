.container {
    display: flex;
    flex-direction: row;    
    height: 100vh;
    @media only screen and (max-width: 768px) {
        flex-direction: column !important;  
    }
}
.container-left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: url('../../../assets/bg/main-dark.png');
    background-size: cover;
    background-position: center;
    padding: 30px 22px 40px 0px;
    @media only screen and (max-width: 768px) {
        width: 100% !important;  
    }
}
.container-right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: url('../../../assets/bg/buy_bg.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 30px;
    flex-direction: row;
    justify-content: space-around;
    @media only screen and (max-width: 768px) {
        width: 100%;  
        padding: 30px 0;
    }
}
.description {
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 0 0 50px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    
}
.desc1 {
    font-size: 60px;
    margin-top: 10px;
    margin-right: 20px;
    @media only screen and (max-width: 1280px) {
        font-size: 50px;
    }
    @media only screen and (max-width: 1024px) {
        font-size: 40px;
    }
    @media only screen and (max-width: 640px) {
        font-size: 30px;
    }
}
.desc2 {
    margin-top: 10px;
    font-size: 13px;
}
.arrow-img {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 90px;
    margin-top: 20px;
    @media only screen and (max-width: 768px) {
        display: none;
    }
}
.arr-img {
    width: 190px;
}
.formContainer {
    color: white;
    max-width: 450px;
    width: 100%;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 1280px) {
        max-width: 350px;  
    }
    @media only screen and (max-width: 1024px) {
        max-width: 250px;  
    }
    @media only screen and (max-width: 768px) {
        max-width: 300px;  
    }
}
.topContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.meta-get {
    background: linear-gradient(#873f99, #311438);
    color: white;
    width: fit-content;
    padding: 5px 10px;
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
}
.form-text1 {
    text-align: center;
    color: #3b3b3b;
    font-size: 22px;
    font-weight: 600;
    margin-top: 60px;
    @media only screen and (max-width: 540px) {
        font-size: 18px;
        margin-top: 30px;
    }
}
.domain {
    color: black;   
    font-weight: bolder;
}
.form-text2 {
    text-align: center;
    color: black;
    font-size: 13px;
    margin: 30px 0;
    @media only screen and (max-width: 768px) {
        margin: 10px 0;
    }
}
.form-text3 {
    color: black;
    font-size: 15px;
    margin-top: 20px;
    @media only screen and (max-width: 768px) {
        font-size: 12px;
        margin-top: 0px;
    }
}
.sigin {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 500;
}
.shop-cart {
     margin-top: 50px;
}
.wrp {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.wallet-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 2px 5px;    
}
.leftContainer {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 200px;
}
.chartContainer {
  border-top: 2px solid #025498;
  width: 35%;//calc(100% / 3 - 124px);
  height: fit-content;
  padding: 25px 10px;
  box-sizing: border-box;
  margin-right: 7px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  .title {
    font-size: 12px;
  }
  .balanceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .balance {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.7;
      word-break: break-all;
      @media only screen and (max-width: 1024px) {
        font-size: 28px;
      }
    }
    .percent {
      display: flex;
      align-items: center;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 600;
      background-color: #2ec814;
      color: white;
      height: 32px;
      padding: 0px 8px;
      white-space: nowrap;
    }
  }
  .dailyChange {
    line-height: 1.5;
    margin-bottom: 12px;
    color: #2ec814;
    font-size: 14px;
    font-weight: 600;
  }
  .chartOptionTab {
    display: flex;
    justify-content: space-between;
    .chartOption {
      align-items: center;
      background: rgb(50, 53, 70);
      border: 0px;
      border-radius: 8px;
      display: inline-flex;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
      -webkit-box-pack: center;
      justify-content: center;
      font-weight: 600;
      width: auto;
      height: 32px;
      font-size: 12px;
      padding: 0px 14px;
      line-height: 18px;      
      @media only screen and (max-width: 1024px) {
        font-size: 10px;
        padding: 0px 5px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 12px;
        padding: 0px 10px;
      }
      svg {
        margin-right: 8px;
      }
    }
    .active {
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 1);
    }
  }
  .chartWrapper {
    margin-top: 24px;
  }
}
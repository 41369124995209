.paypalSymbolImg {
  padding-top: 4px;
}

.mhcTtext {
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  margin-left: 4px;
  font-style: italic;
}
.fontItaly {
  font-style: italic;
  font-weight: 600;
}
.iconDiv {
  display: flex;
  align-items: center;
}

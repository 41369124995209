.btn {
  font-size: 14px;
  color: white;
  padding: 8px 0;
  background: black;
  font-weight: 600;
  text-decoration: navajowhite;
  cursor: pointer;
  width: 120px;
  text-align: center;
  border: none;
}
.search {
  width: 100%;
  height: 45px;
  font-size: 14px;
  padding: 0 10px;
  border: none;
}
.link {
  color: #4da4fb;
}
.headerConteiner {
  padding: 10px;
}
.headerConteiner input {
  vertical-align: middle;
}
.headerConteiner label {
  cursor: pointer;
  margin-right: 10px;
}

.spacebar-item {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 8px 0px;
}
.bottom-space-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
    overflow: auto;
}
.spacebar-table {
    background-color: transparent !important;
    margin-top: 10px;
}

.msg-time {
    color: gray;
}
.msg-type {
    padding-top: 3px;
}

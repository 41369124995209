.return-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.return-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
.return-desc {
    margin-top: 10px;
    font-size: 12px;
}
.return-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media only screen and (max-width: 1280px) {
        font-size: 12px;
    }
    @media only screen and (max-width: 1024px) {
        font-size: 10px;
    }
    @media only screen and (max-width: 420px) {
        font-size: 10px;
    }
}
.return-right-item {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 0px;
    margin: 0 2px;
}
.external-icon {
    width: 14%;
    padding: 17px 0 0 0;
    justify-content: center;
    display: flex;
    margin-left: 10px;
}
.button-copy-link {
    margin-top: 15px;
    border: none;
    outline: none;
    font-size: 14px!important;
    font-weight: bold;
    color: black;
    border-radius: 5px;
    padding: 8px 0px 8px 10px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif !important;
    background: linear-gradient(#873f99, #311438);
    cursor: pointer;
    white-space: nowrap;
    svg {
        margin-right: 12px;
    }
}
.error-msg {
    position: absolute;
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
}

@media (max-width: 640px) { 
}
@media (max-width: 768px) { 
    .button-copy-link {
        font-size: 14px !important;
    }  
}
@media (max-width: 1024px) {
  
}
@media (max-width: 1280px) { 
    .button-copy-link {
        font-size: 10px !important;
    }  
}
@media (max-width: 1536px) { 
}
.react-tag-input {
    background: transparent!important;
}
.react-tag-input {
    position: absolute!important;
    margin-top: 0px;
    padding-top: 40px;
    /*border: 1px solid red!important;*/
    border: none;
    width: calc(100% - 30px);
    min-height: calc(100% - 30px);
    align-items: flex-start;
    align-content: flex-start;
}
.react-tag-input__input {
    border: 1px solid red;
    position: absolute;
    height: 30px;
    font-family: "Montserrat", sans-serif !important;
    width: calc(100% - 30px);
    font-size: 14px;
    top: 0;
    left: 0px;
    border: none;
}
.react-tag-input__input::placeholder {
    color: #c5c5c5;
}
.react-tag-input__tag {
    height: 25px;
    /* background: #101517; */
    /* color: white; */
    border-radius: 50px;
    font-size: 12px;
    margin-bottom: 5px;
    padding: 5px;
}
.react-tag-input__tag__remove {
    /* background: linear-gradient(#ffc600,#f48f33); */
    height: 2.1em;
    font-size: 10px;
    border-radius: 50px;
}

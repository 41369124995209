.header-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
}
.header-btns a {
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 15px;
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;  
  width: 100px;
  &:hover {
    font-weight: 700;
    font-size: 13px;
  }
}

.modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  position: fixed;
  overflow: hidden;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  color: black;
  transition: opacity 0.3s linear;

  &-content {
    width: 50vw;
    padding: 25px 30px;
    background: white;
    max-height: 80vh;
    overflow: auto;
    position: relative;
    border-radius: 10px;

    .modalTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0 30px;

      p {
        font-size: 22px;
        font-weight: bold;
        text-decoration: underline;
      }
      .modal-close {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

.modal-content__btns {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 35px;
  margin-top: 24px;
}

.modal-content__btns .btn {
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  display: flex;
  cursor: pointer;
  justify-content: center;
  width: 60px;
  align-items: center;
  &:hover {
    transform: scale(1.05);
  }
}
.modal-content__btns .btn:first-child {
  color: #fdbe08;
  border: 1px solid #fdbe08;
}
.modal-content__btns .btn:first-child:hover {
  //color: #fdae08;
  border-color: #fdae08;
}
.modal-content__btns .btn:last-child {
  margin-left: 25px;
  height: 37px;
  background: linear-gradient(#873f99, #311438);
  border: none;
}
.modal-content__btns .btn:last-child:hover {
  background: linear-gradient(#873f99, #311438);
}
.modal-content__btns .btn:last-child:active {
  background: linear-gradient(#873f99, #311438);
}

.modal.animated-enter {
  opacity: 0.01;
}
.modal.animated-enter-active {
  opacity: 1;
}

.modal.animated-exit {
  opacity: 0;
}
.modal.animated-exit-active {
  opacity: 0;
}

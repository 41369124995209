.hashtagCart {
  width: 100%;
  margin-bottom: 10px;
  align-self: flex-start;
  font-weight: 400;
  font-size: 14px;
  display: block;
  background: '#ffffff90';
  height: 63vh;
  @media only screen and (max-width: 540px) {
    max-width: 150px;  
    font-size: 11px;
  }
  &-header {
    height: 50px;
    padding: 0 18px;
    align-items: center;
    display: flex;
    background: linear-gradient(#bdbcbc, #f3f3f3, #fff, #fff, #fff, #f3f3f3, #bdbcbc);
    color: black;
    svg {
      margin-right: 10px;
    }
  }
  &-body {
    min-height: 51vh;
    position: relative;
    overflow-y: auto;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_item {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      &.discount {
        height: 0px;
        margin-bottom: 20px;
      }
      .cartName {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.demo {
          color: #ffc600;
        }
      }
      .close {
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s, opacity 0.5s linear;
        position: absolute;
        right: 0px;
      }
      .prices {
        opacity: 1;
        visibility: visible;
        display: inline;
        transition: visibility 0s, opacity 0.5s linear;
      }
    }
    &_item:hover {
      .prices {
        opacity: 0.8;
        // visibility: hidden;
        color: gray;
      }
      .close {
        opacity: 1;
        visibility: visible;
      }
    }
    &_empty {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      white-space: nowrap;
      height: fit-content;
      font-size: 12px;
      height: 45vh;
      .zero {
        margin: 15px 0;
        font-size: 30px;
      }
    }
  }
  .hashtagCartBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hashtagCart-btn {
    border: none;
    outline: none;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgb(20, 137, 240);
    color: white;
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: 'Montserrat', sans-serif !important;
    cursor: pointer;
  }
  .hashtagCart-btn:hover {
    background: #58a0e5;
  }
  .hashtagCart-btn:disabled {
    cursor: auto;
    opacity: 0.6;
    &:hover {
      background: #58a0e5;
    }
  }
  .subTotal {
    border-top: 1px solid gray;
  }
  .subTotalExplain {
    text-align: center;
    font-size: 12px;    
  }
  .w600 {
    font-weight: 600;
  }
}

// @media only screen and (max-width: 1366px) {
//   .hashtagCart {
//     max-width: 220px;
//     font-size: 14px;
//     .hashtagCart-btn {
//       font-size: 14px !important;
//     }
//   }
// }
// .test {
//   color: #0000002d #c5c9cd2d;
//   background: linear-gradient(#abacac, #e2e2e2, #abacac);
// }
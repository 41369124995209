.sendForm {
  background: rgba(0, 0, 0, 0.7);
  border-top: 2px solid #025498;
  padding: 14px;
  /* margin: 0 12px; */
  /* width: calc(100% / 3); */
  position: relative;
}
.sendForm_l {
  background: #ffffff90;
  border-top: 2px solid gray;
  padding: 14px;
  /* margin: 0 12px; */
  /* width: calc(100% / 3); */
  position: relative;
}
form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.sendCreditLevel {
  display: flex;
  position: absolute;
  top: 20px;
  left: 200px;
}
.sendCreditLevel .levelText {
  margin-right: 8px;
}
.notificationAlert {
  position: absolute;
  font-size: 12px;
  color: #f5eee0;
  white-space: nowrap;
  top: 21px;
  left: 170px;
}
.formLabel {
  width: 100%;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
}
.payInp {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.payInp .input label {
  margin-bottom: 0px;
}

.payInp .sendCreditInp {
  position: relative;
  min-width: 50%;
  margin-right: 10px;
}

.sendCreditInp .maxBtn {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-28%);
  background-color: transparent;
  border: none;
  color: white;
}
.sendCreditInp .maxBtn:hover {
  color: #fbb212;
}

.btnCont {
  display: flex;
}
.sendBtn,
.sendConfirmBtn {
  border: none;
  outline: none;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: center;
  margin: 0 1px;
  padding: 0px;
  width: 90px;
  height: 40px;
  border-radius: 5px;
}
.sendBtn:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.sendBtn:disabled {
  cursor: default;  
  transform: scale(1);
}
.paypal {
  position: absolute;
  width: 50px;
  top: 0;
  left: 65px;
}

.sendingOptionPanel {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid #03569c;
  top: 140px;
  left: 0px;
  padding: 16px 12px 0;
  box-sizing: border-box;
}

.senCreditContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.sendCreditText {
  font-size: 16px;
}
.nickNameText {
  color: #fbb212;
  font-weight: 700;
}
.btnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.zeroPercentText {
  font-size: 18px;
}
.zeroPercentText .zeroText {
  font-size: 32px;
}
.sendConfirmBtn {
  width: 170px;
  margin: 24px 0;
}

.divideLine {
  width: 100%;
  height: 1px;
  background-color: #35495e;
}

.directPaymentContainer {
  width: 100%;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.directPaymentTitle {
  font-size: 12px;
  color: #919599;
  font-weight: 500;
}

.directPaymentOption {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.directPaymentOption .paymentBtn {
  height: 25px;
  padding-right: 12px;
  margin-left: 16px;
}
.paymentBtn .starContainer {
  margin-top: 5px;
}

.optionText {
  font-weight: 800;
  font-style: italic;
}

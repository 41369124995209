// .container-wrapper {
//     display: flex;
//     flex-direction: row;
//     overflow-y: auto;
//     &__main {
//         padding: 30px 50px;
//         z-index: 1;
//     }
// }
.top-item-backslash {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 30px 30px 0px 40px;
    z-index: 0;
}
.top-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
.bottom-item-backslash {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 40px 50px;
    z-index: 0;
    // @media only screen and (max-width: 1024px) {
    //     padding: 0px 20px 40px;
    // }
    @media only screen and (max-width: 768px) {
        padding: 0px 40px 50px;
    }
    @media only screen and (max-width: 420px) {
        padding: 0px 20px 40px;
    }
}
.button-lay {
    margin-top: 15px;
    border: none;
    outline: none;
    font-size: 16px!important;
    font-weight: bold;
    color: black;
    border-radius: 5px;
    padding: 8px 8px;
    width: 150px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif !important;
    background: linear-gradient(#873f99, #311438);
    cursor: pointer;
    @media only screen and (max-width: 1024px) {
        width: 100px;
        white-space: nowrap;
    }
    @media only screen and (max-width: 768px) {
        width: 140px;
    }
}

@keyframes anim-opacity {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

@media (max-width: 640px) { 
}
@media (max-width: 768px) { 
    .bottom-left {
        font-size: 14px !important;
    }  
}
@media (max-width: 1024px) {
    .bottom-left {
        font-size: 10px;
    }    
}
@media (max-width: 1280px) { 
}
@media (max-width: 1536px) { 
}
.template {
  width: calc(100% - 235px - 50px);
  margin-right: 15px;
  background: fade-out(#000, 0.4);
  padding-top: 2px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: #02569d;
  }
  @media only screen and (max-width: 1366px) {
    width: calc(100% - 180px - 50px);
  }  
  @media only screen and (max-width: 1024px) {
    width: calc(100% - 150px - 50px);
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 110px - 50px);
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
  }

  & .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    padding: 20px 32px;
    @media only screen and (max-width: 1366px) {
      padding: 15px;
    }
  }
  & .tabs {
    padding: 0 32px;
    @media only screen and (max-width: 1366px) {
      padding: 0 15px;
    }
    & .tablist {
      display: flex;
      align-items: center;
      margin: 0 -15px;
      padding-bottom: 20px;
      & .tab {
        display: block;
        margin: 0 15px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #26a4ff;
        cursor: pointer;
        outline: none;

        &:global(.react-tabs__tab--selected) {
          color: #fff;
        }
      }
    }

    & .tabpanel {
      h4 {
        margin: 30px 0 0;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
      }
      p {
        margin: 20px 0 0;
        font-size: 12px;
        line-height: 15px;
        color: #ffffff;
        max-width: 450px;
        &:global(.hint) {
          margin: 12px 0 0;
          font-size: 12px;
          line-height: 15px;
          color: #ffffff;
          max-width: 100%;
        }
      }
      a {
        color: #26a4ff;
        text-decoration: none;
      }
    }
  }  
  & .actions {
    padding: 20px 32px 30px;
    margin: 0 -10px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    :global(.spacer) {
      flex: 1 0 auto;
    }
    & .button {
      margin: 0 10px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #000000;
      border: none;
      background: linear-gradient(180deg, #ffc601 0%, #f59033 100%);
      outline: none;
      cursor: pointer;
      border-radius: 5px;      
      @media only screen and (max-width: 1024px) {
        height: 34px;
        padding: 0 5px;
        font-size: 12px;
      }
      &:disabled {
        background: #a2a2a2;
        cursor: default;
      }
      &:global(.transparent) {
        padding: 0;
        color: #26a4ff;
        background: rgba(0, 0, 0, 0);
      }
    }
  }
}
.template_light {
  width: calc(100% - 235px - 50px);
  margin-right: 15px;
  background: #fff;
  padding-top: 2px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: #02569d;
  }

  @media only screen and (max-width: 1366px) {
    width: calc(100% - 180px - 50px);
  }  
  @media only screen and (max-width: 1024px) {
    width: calc(100% - 150px - 50px);
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 110px - 50px);
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
  }

  & .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    padding: 20px 32px;
    @media only screen and (max-width: 1366px) {
      padding: 15px;
    }
  }
  & .tabs {
    padding: 0 32px;
    @media only screen and (max-width: 1366px) {
      padding: 0 15px;
    }
    & .tablist {
      display: flex;
      align-items: center;
      margin: 0 -15px;
      padding-bottom: 20px;
      & .tab {
        display: block;
        margin: 0 15px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #26a4ff;
        cursor: pointer;
        outline: none;

        &:global(.react-tabs__tab--selected) {
          color: #000;
        }
      }
    }

    & .tabpanel {
      h4 {
        margin: 30px 0 0;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #000;
      }
      p {
        margin: 20px 0 0;
        font-size: 12px;
        line-height: 15px;
        color: #000;
        max-width: 450px;
        &:global(.hint) {
          margin: 12px 0 0;
          font-size: 12px;
          line-height: 15px;
          color: #000;
          max-width: 100%;
        }
      }
      a {
        color: #26a4ff;
        text-decoration: none;
      }      
    }
  }
  & .actions {
    padding: 20px 32px 30px;
    margin: 0 -10px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    :global(.spacer) {
      flex: 1 0 auto;
    }
    & .button {
      margin: 0 10px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #000000;
      border: none;
      background: linear-gradient(180deg, #b7eaf6 0%, #777fc4 100%);
      outline: none;
      cursor: pointer;
      border-radius: 5px;     
      @media only screen and (max-width: 1024px) {
        height: 34px;
        padding: 0 5px;
        font-size: 12px;
      }
      &:disabled {
        background: #a2a2a2;
        cursor: default;
      }
      &:global(.transparent) {
        padding: 0;
        color: #26a4ff;
        background: #ffffff00;
      }
    }
  }
}

.theme {
  width: 320px;
  background: fade-out(#000, 0.4);
  position: relative;
  padding-top: 2px;
  @media only screen and (max-width: 1366px) {
    width: 220px;
  }
  @media only screen and (max-width: 1024px) {
    width: 180px;
  }
  @media only screen and (max-width: 768px) {
    width: 150px;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-bottom: 10px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: #02569d;
  }
  & .item {
    min-height: 56px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    flex-wrap: nowrap;
    position: relative;
    text-decoration: none;    
    @media only screen and (max-width: 640px) {
      min-height: 30px;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      content: '';
      opacity: 0.1;
      border: 1px solid #ffffff;
      z-index: 2;
    }
    &:first-child {
      &::before {
        display: none;
      }
    }
    &:global(.green) {
      .status {
        background: #27ae60;
      }
    }
    &:global(.red) {
      .status {
        background: #eb5757;
      }
    }
    &:global(.active) {
      .text {
        color: #ffffff;
      }
    }
    & .status {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 10px;
      background: #ffffff;
    }
    & .text {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #26a4ff;
      cursor: pointer;
    }
  }
}
.theme_light {
  width: 320px;
  background: #fff;
  position: relative;
  padding-top: 2px;
  @media only screen and (max-width: 1366px) {
    width: 220px;
  }  
  @media only screen and (max-width: 1024px) {
    width: 180px;
  }
  @media only screen and (max-width: 768px) {
    width: 150px;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-bottom: 10px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: #02569d;
  }
  & .item {
    min-height: 56px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    flex-wrap: nowrap;
    position: relative;
    text-decoration: none;
    @media only screen and (max-width: 640px) {
      min-height: 30px;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      content: '';
      opacity: 0.1;
      border: 1px solid #000;
      z-index: 2;
    }
    &:first-child {
      &::before {
        display: none;
      }
    }
    &:global(.green) {
      .status {
        background: #27ae60;
      }
    }
    &:global(.red) {
      .status {
        background: #eb5757;
      }
    }
    &:global(.active) {
      .text {
        color: #000;
      }
    }
    & .status {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 10px;
      background: #000;
    }
    & .text {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #26a4ff;
      cursor: pointer;
    }
  }
}

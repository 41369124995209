.enterContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  width: 100%;
}
.enterTop {
  width: 100%;
  height: 80px;  
  display: flex;
  flex-direction: row;
  align-items: center;  
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    height: 160px;
  }
}
// .gamebling_img {
//   width: 100%;
// }
.stat_img {
  width: 20px;
}
.enterMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 15px;
  @media only screen and (max-width: 540px) {
    padding: 5px;
  }
}
.subEMain {
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
}
.eMainLeft {
  display: flex;
  flex-direction: column;
  width: 25%;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}
.eMainRight {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 20px;  
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
}
.eActivity {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.eStats {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border: 1px solid;
}
.game-balance-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 5px 0px 5px;
}
.game-balance-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.game-balance-table {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.g-green {
  color: #12d48c;
}
.g-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
}
.g-num {
  font-size: 18px;
  margin-right: 10px;
}
.g-t-num {
  color: #12d48c;
  font-size: 18px;
  margin-right: 10px;
}
.g-table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;    
  margin: 7px 0;
  padding: 0 0 7px 0;
}
.dash_border {
  border-bottom: 1px solid gray;
  border-bottom-style: dashed;
}
.trophy_icon {
  width: 18px;
  margin-right: 10px;
}


.eTopLeft {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  position: relative;
  @media only screen and (max-width: 1336px) {
    padding: 0 10px;   
  }
  @media only screen and (max-width: 1024px) {
    padding: 0 5px;   
    width: 100%;
  }
}
.discord_icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}
.eTopRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  width: 100%;
  @media only screen and (max-width: 1280px) {
    padding: 0 5px; 
  }
  // @media only screen and (max-width: 1280px) {
  //   flex-direction: column;
  //   align-items: start;
  // }
}
.eTopRight-l {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70%;
  @media only screen and (max-width: 1536px) {
    flex-direction: column;
  }
}
.eTopRight-r {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 30%;
}
.plus_icon {
  margin: 0 5px;
  cursor: pointer;
}
.e-desc {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.improve {
  font-weight: 700;
  text-decoration: underline;
  margin: 0 5px;
  white-space: nowrap;
  font-size: 14px;
}
.better {
  font-size: 13px;
  margin: 0 5px;
}
.userdetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-right: 15px;
}
.user_avatar {
  position: relative;
}
.dot_online {
  position: absolute;
  bottom: 0px;
  left: 3px;
}
.game_title {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
}
.margin5 {
  margin: 0 5px;
}
.welcome {
  font-size: 12px;
  margin: 5px 0 0 30px;
}
.user_nick {
  font-size: 14px;
  font-weight: 600;
  margin-left: 30px;
}



.heat_stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}
.activity_table_container {
  margin-top: 15px;
  border: 1px solid;
}
.activ_table_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  padding: 15px;
}
.act-table {
  max-height: 300px;
  overflow: scroll;
}
.act-tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}
.act-td {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  font-size: 12px;
}
.chooseArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
}
.chooseLeft {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px;
  font-size: 14px;
  position: relative;
}
.chooseRight {
  background: #12d48c;
  font-size: 25px;
  font-weight: 700;
  padding: 10px;
  height: 80px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;  
  @media only screen and (max-width: 1660px) {
    font-size: 20px;
    width: 130px;
  }
  @media only screen and (max-width: 1336px) {
    font-size: 18px;
    width: 80px;
  }
  @media only screen and (max-width: 1336px) {
    font-size: 18px;
    width: 100px;
  }
}
.choose-select {
  background: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer;
  width: fit-content;
  border-bottom: 1px solid;
  border-bottom-style: dashed;
}
.choose-select:focus {
  outline: none;
}
.claim_icon {
  width: 100%;
  position: absolute;
  right: 0px;
  bottom: 5px;
  @media only screen and (max-width: 1660px) {
    bottom: 10px;    
  }
  @media only screen and (max-width: 1536px) {
    width: 96%;
    bottom: 15px;
  }  
  @media only screen and (max-width: 1280px) {
    width: 92%;
    // left: 25px;
  }
  @media only screen and (max-width: 1024px) {
    width: 88%;
    // left: 25px;
  }
}


.bodyArea {
  width: 100%;
  min-height: 550px;
  border: 10px solid;
  margin-top: 15px;
  font-size: 14px;
}
.b_map {
  height: 480px;
  width: 100%;
  margin: 10px;
}
.b_img {
  width: 100%;
}
.b_item {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
}
.b_padding {
  padding: 5px;
}
.body-select {
  background: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer;
  width: fit-content;
}
.bodyDetailContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 1336px) {
    flex-direction: column;
  }
}
.b_left {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-evenly;
  width: 40%;
  margin: 20px 0;
  @media only screen and (max-width: 1336px) {
    width: 100%;
  }
}
.b_right {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: end;
  @media only screen and (max-width: 1336px) {
    width: 100%;
  }
}
.b_percent {
  display: flex;
  flex-direction: column;
}
.p_circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  @media only screen and (max-width: 1336px) {
    width: 110px;
    height: 110px;
  }
}
.font12 {
  font-size: 12px;
}
.go_btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100px;
  height: 50x;
  font-size: 16px;
  font-weight: 700;
  padding: 5px;
  border-radius: 3px;
  border: none;
  background: #12d48c;
  color: white;
  cursor: pointer;
}
.subEBottom {
  margin-top: 15px;
}
.matchArea {
  margin-top: 15px;
}
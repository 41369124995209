.networkWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.networkContent {
  width: 30%;
  position: relative;
  border: 1px solid #0093fc;
  &:hover {
    border: none;
  }

  .networkLogo {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
  .checkIcon {
    position: absolute;
    bottom: -8px;
    right: -8px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: #0093fc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
  }
}
.menuIconContainer {
  position: relative;
}

.menuIconContainer .menuIcon {
  width: 26px;
  height: 26px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 22px;
}
.menuIconContainer .menuIcon_d {
  width: 26px;
  height: 26px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 22px;
  filter: brightness(0.2);
}
.sparkEffect {
  position: absolute;
  bottom: -8px;
  left: -14px;
  width: 54px;
}

@media (max-width: 640px) { 
}
@media (max-width: 768px) { 
}
@media (max-width: 1024px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1536px) { 
}
@media (max-width: 1660px) { 
}
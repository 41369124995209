.login-inp {
    width: 100%;
}
.login-inp label {
    font-size: 14px;
}
.login-inp input {
    height: 55px;
    border: 1px solid gray;
    outline: black;
    font-size: 16px;
    padding: 0 25px;
    border-radius: 50px;
    display: block;
    max-width: 450px;
    width: 100%;
    margin: 3px 0 15px;
    box-sizing: border-box;
    @media only screen and (max-width: 768px) {
        height: 45px;
    }
}
.login-inp.error label{
    color: red;
}
.login-inp.error input{
    border:5px solid red;
}

.PhoneInputCountry {
    font-size: 30px;
    margin-top: -15px;
}
.PhoneInputInput {
    height: 50px !important;
    border: 1px solid gray;
    font-size: 16px;
    padding: 0 25px;
    border-radius: 50px;
    display: block;
    width: 370px;
    margin-bottom: 20px;
    box-sizing: border-box;
    &:active {
        border: none;
    }
    @media only screen and (max-width: 768px) {
        height: 45px !important;
    }
}


@media (max-width: 480px) { 
    .PhoneInputInput {
        width: 100%;
    }
}
@media (max-width: 768px) { 
}
@media (max-width: 1024px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1536px) { 
}
@media (max-width: 1660px) { 
}

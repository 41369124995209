.wraper {
  width: 100%;
  z-index: 0;
}
.form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 25px 40px 25px;
  background: rgba(0, 0, 0, 0.8);
  border-top: 2px solid #025498;
  flex-direction: row;
  flex-wrap: wrap;
}
.formCol1 {
  flex-basis: calc(20% - 25px);
  padding-right: 25px;
}
.formCol3 {
  flex-basis: calc(30% - 45px);
  padding-left: 45px;
}
.checkboxAftertit {
  font-size: 12px;
}
.formTit {
  font-size: 18px;
  margin-bottom: 30px;
}
.checkGroup {
  margin-bottom: 60px;
}
.threecol {
  display: flex;
  flex-direction: row;
  width: 300px;
  margin-bottom: 20px;
}
.threecol.long {
  width: 100%;
}
.mark {
  margin-left: 10px;
  margin-top: 30px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100%;
}
.threecollast {
  display: block;
}
.twocol {
  display: flex;
  width: calc(100% / 2);
  margin-top: 40px;
}
.onecol {
  display: flex;
  width: 100%;
}
.formRow {
  margin-bottom: 15px;
}
.border {
  border-bottom: 1px solid #354955;
  margin: 45px 0;
  width: calc(100% - 10px);
}
.modtit {
  font-size: 14px;
  font-weight: 600;
}
.formBtn {
  border: none;
  outline: none;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  background: -webkit-gradient(linear, left top, left bottom, from(#873f99), to(#311438));
  background: linear-gradient(#873f99, #311438);
  color: #000;
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-top: 20px;
  border-radius: 5px;
}
.formBtn:hover {
  background: linear-gradient(#873f99, #311438);
  cursor: pointer;
}
.formBtn:active {
  background: linear-gradient(#873f99, #311438);
}
.formBtn:disabled {
  background: #a2a2a2;
  cursor: default;
}
.switchlabel {
  margin-bottom: 4px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}
.switch {
  margin-top: 10px;
}
.switch svg {
  display: none;
}
.formSetting {
  margin-right: 150px;
}


@media (max-width: 640px) { 
}
@media (max-width: 768px) { 
}
@media (max-width: 1024px) {
}
@media (max-width: 1280px) {
  .formSetting {
    margin-right: 50px !important;
  }
}
@media (max-width: 1536px) { 
}
@media (max-width: 1660px) { 
}
/* @media only screen and (max-width: 1200px) {
  .formCol1 {
    flex-basis: calc(20% - 15px);
    padding-right: 15px;
  }
  .formSetting {
    flex-basis: 50%;
  }
  .formCol3 {
    flex-basis: calc(30% - 15px);
    padding-left: 15px;
  }
  .formTit {
    font-size: 14px;
  }
  .modtit {
    font-size: 12px;
  }
} */

.credit_sendForm {
  background: rgba(0, 0, 0, 0.7);
  border-top: 2px solid #025498;
  padding: 14px;
  margin: 0 12px 25px 12px;
  /* width: calc(100% / 3); */
  position: relative;
}
.credit_sendForm_l {
  background: #fff;
  border-top: 2px solid #000;
  padding: 14px;
  margin: 0 12px 25px 12px;
  /* width: calc(100% / 3); */
  position: relative;
}
.credit_sendForm_d {
  background: rgba(0, 0, 0, 0.7);
  border-top: 2px solid #fff;
  padding: 14px;
  margin: 0 12px 25px 12px;
  /* width: calc(100% / 3); */
  position: relative;
}
form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.credit_sendCreditLevel {
  display: flex;
  position: absolute;
  top: 20px;
  left: 200px;
}
.credit_sendCreditLevel .credit_levelText {
  margin-right: 8px;
}
.credit_notificationAlert {
  position: absolute;
  font-size: 12px;
  color: #f5eee0;
  white-space: nowrap;
  top: 21px;
  left: 170px;
}
.credit_formLabel {
  width: 100%;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
}
.credit_payInp {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5px;
  margin-top: -4px;
  width: 100%;
}

.credit_payInp .credit_sendCreditInp {
  position: relative;
  min-width: 50%;
  margin-right: 5px;
}
.test {
  color: #0063cc;
}
/* .credit_sendCreditInp .credit_maxBtn {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-28%);
  background-color: transparent;
  border: none;
  color: white;
}
.credit_sendCreditInp .credit_maxBtn:hover {
  color: #fbb212;
} */

.credit_btnCont {
  display: flex;
}
.credit_payBtn,
.credit_sendConfirmBtn {
  border: none;
  outline: none;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff;
  font-size: 12px !important;
  color: #ffffff;
  font-weight: 700 !important;
  text-align: center;
  margin: 0 1px;
  padding: 0px;
  width: 90px;
  height: 42px;
  background-color: #0093fc;
  border-radius: 10px;
}
.credit_payBtn:hover {
  /* background: linear-gradient(#ffc600, #f48f73); */
  cursor: pointer;
  transform: scale(1.1) ;
}
/* .credit_payBtn:active {
  background: linear-gradient(#f48f33, #fbb212);
} */
.credit_payBtn:disabled {
  background: #606061;
  cursor: default;
}
.credit_paypal {
  position: absolute;
  width: 50px;
  top: 0;
  left: 65px;
}

.credit_sendingOptionPanel {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid #03569ca8;
  top: 45px;
  border-radius: 5px;
  /* left: 30%; */
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;
}

.credit_senCreditContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.credit_sendCreditText {
  font-size: 16px;
}
.credit_nickNameText {
  color: #fbb212;
  font-weight: 700;
}
.credit_btnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.credit_zeroPercentText {
  font-size: 18px;
}
.credit_zeroPercentText .credit_zeroText {
  font-size: 32px;
}
.credit_sendConfirmBtn {
  width: 170px;
  margin: 24px 0;
}

.credit_divideLine {
  width: 100%;
  height: 1px;
  background-color: #35495e;
}

.credit_directPaymentContainer {
  width: 100%;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.credit_directPaymentTitle {
  font-size: 12px;
  color: #919599;
  font-weight: 500;
}

.credit_directPaymentOption {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.credit_directPaymentOption .credit_paymentBtn {
  height: 25px;
  padding-right: 12px;
  margin-left: 16px;
}
.credit_paymentBtn .credit_starContainer {
  margin-top: 5px;
}

.credit_optionText {
  font-weight: 800;
  font-style: italic;
}

.control {
  position: relative;
  display: block;
  padding-left: 35px;
  cursor: pointer;

  &:global(.control--toggle) {
    padding-left: 52px;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    min-height: 20px;
    display: flex;
    align-items: center;

    .indicator {
      display: none;
    }
  }

  &:global(.control--checkbox) {
    @media only screen and (max-width: 1200px) {
      font-size: 14px;
    }

    .toggle {
      display: none;
    }

    .indicator {
      &:after {
        top: 4px;
        left: 8px;
        width: 3px;
        height: 8px;
        transform: rotate(45deg);
        border: solid black;
        border-width: 0 2px 2px 0;
      }
    }
  }

  &:global(.cn__sinccheck) {
    display: flex;
    height: 26px;
    padding-top: 0px;
    align-items: center;
  }

  &:global(.readOnly) {
    background: #5a5a5a;
    cursor: default;
  }

  & .indicator {
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid;
    border-radius: 4px;

    &:after {
      position: absolute;
      display: none;
      content: '';
    }
  }

  & .toggle {
    width: 40px;
    height: 20px;
    border-radius: 10px;
    background: #c4c4c4;
    position: absolute;
    top: 0;
    left: 0;

    & .bar {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: #ffffff;
      transform: translate(2px, 2px);
    }
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked {
      & ~ .indicator {
        background: white;

        &:after {
          display: block;
        }
      }

      & ~ .toggle {
        background: #27ae60;

        & .bar {
          transform: translate(22px, 2px);
        }
      }
    }

    &:disabled ~ .indicator {
      pointer-events: none;
      opacity: 0.6;
      background: #e6e6e6;
      & ~ .toggle {
        background: #a2a2a2;
      }
      &:after {
        border-color: #7b7b7b;
      }
    }
  }
}

.select {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0px;
  border: 1px solid white;
}

.select select {
  //display: inline-block;
  width: 100%;
  display: flex;
  height: 40px;
  padding: 10px 10px;
  cursor: pointer;
  color: #7b7b7b;
  border: 0;
  border-radius: 0;
  outline: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
}

.select select::-ms-expand {
  display: none;
}

.select select:hover,
.select select:focus {
  //color: #000;
  //background: #ccc;
}

.select select:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.select__arrow {
  position: absolute;
  top: 18px;
  right: 10px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-width: 6px 4px 0 4px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}
.select select:hover ~ .select__arrow,
.select select:focus ~ .select__arrow {
  border-top-color: white;
}

.select select:disabled ~ .select__arrow {
  border-top-color: #ccc;
}

.control--checkbox {
  font-size: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qr {
  height: 200px;
  width: 200px;
}
.container input {
  height: 50px;
  font-size: 58px;
  text-align: center;
  padding: 0;
}
.container input.s {
  font-size: 34px;
}
.error {
  color: red;
}
.preloader {
  width: 20px;
  height: 20px;
  padding: 90px;
}
